import React, { useEffect, useState } from "react";
import styles from "./companyDetails.module.css";
import { TextField } from "@mui/material";
import { Carousel } from "react-bootstrap";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { getReq, postReq } from "../../piservices/apis";
import { apiEndpoints } from "../../piservices/enums";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-number-input";
import idesign from "../../Assets/logo.png";
import { BASE_URL, apiLinks, base_url, emailValidation, numberArray } from "../../piservices/constants";
import { validationFunction } from "../../piservices/commonService";
import MuiPhoneNumber from "material-ui-phone-number";
import { getToken } from "../../piservices/authService";

const CompanyDetails = () => {
  const navigateTo = useNavigate();

  const [profileData, setProfileData] = useState({});

  const getProfileData = async () => {
    const res = await getReq(`${apiLinks.crm}/user/profile`, {}, { Authorization: `Bearer ${getToken()}` });
    if (res && !res.error) {
      setProfileData({ ...res.data.data });
    } else {
      localStorage.clear();
      window.location.assign(`${BASE_URL}`);
    }
  };

  useEffect(() => {
    getProfileData();
  }, []);

  console.log(profileData);

  const [error, seterror] = useState({
    CName: false,
    email: false,
    address: false,
    city: false,
    zipCode: false,
    gst: false,
    number: false,
  });

  const [input, setinput] = useState({
    CName: "",
    email: "",
    address: "",
    city: "",
    zipCode: "",
    gst: "",
    number: "",
    countryCode: "",
  });

  const [errors, setErrors] = useState({
    email: "",
  });
  const handleInput = (event) => {
    const { name, value } = event.target;
    // if (name === "email") {
    //   let valText = validationFunction(emailValidation[name], value);
    //   setErrors((prev) => {
    //     return {
    //       ...prev,
    //       email: valText,
    //     };
    //   });
    // }

    // if (value) {
    //   seterror((prev) => {
    //     return {
    //       ...prev,
    //       [name]: false,
    //     };
    //   });
    // } else {
    //   seterror((prev) => {
    //     return {
    //       ...prev,
    //       [name]: true,
    //     };
    //   });
    // }

    if (name === "zipCode") {
      if (value.length < 7) {
        setinput((prev) => {
          return {
            ...prev,
            zipCode: value,
          };
        });
      }
    }

    if (name === "gst") {
      if (value.length < 16) {
        setinput((prev) => {
          return {
            ...prev,
            gst: value,
          };
        });
      }
    }

    if (name === "CName") {
      if (value.length < 30) {
        setinput((prev) => {
          return {
            ...prev,
            CName: value,
          };
        });
      }
    }
    if (name === "address") {
      if (value.length < 50) {
        setinput((prev) => {
          return {
            ...prev,
            address: value,
          };
        });
      }
    }

    if (name === "city") {
      let check = numberArray.some((elem) => {
        return value.includes(elem);
      });
      if (!check) {
        if (value.length < 20) {
          setinput((prev) => {
            return {
              ...prev,
              city: value,
            };
          });
        }
      }
    }

    if (name === "email") {
      setinput((prev) => {
        return {
          ...prev,
          email: value,
        };
      });
    }

    if (name === "number") {
      if (value?.length < 11) {
        setinput((prev) => {
          return {
            ...prev,
            [name]: value,
          };
        });
      }
    }
  };

 
  // company details
  const companyDetails = async () => {
    const payload = new FormData();
    payload.append("companyName", input.CName);
    payload.append("email", profileData.email);
    payload.append("address", profileData.address);
    payload.append("city", profileData.city);
    // payload.append("phoneNumber ", parseInt(profileData.phoneNumber.split(" ")[1]));
    // payload.append("countryCode", profileData.phoneNumber.split(" ")[0]);
    payload.append("gstNumber", input.gst);
    // payload.append("isDefault",true);
    const res = await postReq(`${apiLinks.crm}/user/updateProfile`, payload);
    if (res && !res.error) {
      setinput({
        CName: "",
        email: "",
        address: "",
        city: "",
        zipCode: "",
        gst: "",
        number: "",
      });
      navigateTo("/team-onboard");
    } else {
      console.error(res.error);
    }
  };
  return (
    <>
      <div className={styles.container}>
        <div className={styles.first_contain}>
          <img src={idesign} alt="" style={{ height: "2rem", position: "absolute", top: "2rem", left: "4rem" }} />

          <Carousel
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "85%",
            }}
            nextIcon=""
            nextLabel=""
            prevIcon=""
            prevLabel=""
          >
            <Carousel.Item style={{ boxShadow: "none", borderBottom: "none" }}>
              <div className={styles.ImgDiv}>
                <img src="https://idesign-office.s3.ap-south-1.amazonaws.com/Rectangle%207461%20%281%29.png" alt="First slide" className={styles.imgg} />
              </div>
              <div style={{ backgroundColor: "#E9EFFB", paddingLeft: "1rem", display: "flex", flexDirection: "column" }}>
                <img src="https://idesign-office.s3.ap-south-1.amazonaws.com/Group.svg" alt="" style={{ height: "2.3rem", width: "2.3rem", marginTop: "1.5rem", marginBottom: "1rem" }} />
                <div style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
                  <div className={styles.heading}>FILE MANAGER</div>
                  <div className={styles.para}>Easily organize and manage all of your important documents with our file manager feature.</div>
                </div>
              </div>
            </Carousel.Item>

            <Carousel.Item style={{ boxShadow: "none", borderBottom: "none" }}>
              <div className={styles.ImgDiv}>
                <img src="https://idesign-office.s3.ap-south-1.amazonaws.com/Rectangle%207467%20%282%29.png" alt="Second slide" className={styles.imgg} />
              </div>

              <div style={{ backgroundColor: "#E9EFFB", paddingLeft: "1rem", display: "flex", flexDirection: "column" }}>
                <img src="https://idesign-office.s3.ap-south-1.amazonaws.com/Group1.svg" alt="" style={{ height: "2.3rem", width: "2.3rem", marginTop: "1.5rem", marginBottom: "1rem" }} />
                <div style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
                  <div className={styles.heading}>TASK MANAGEMENT</div>
                  <div className={styles.para}>Optimize resource usage and ensures that projects are completed efficiently and on time.</div>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item style={{ boxShadow: "none", borderBottom: "none" }}>
              <div className={styles.ImgDiv}>
                <img src="https://idesign-office.s3.ap-south-1.amazonaws.com/Rectangle%207462%20%283%29.png" alt="Third slide" className={styles.imgg} />
              </div>

              <div style={{ backgroundColor: "#E9EFFB", paddingLeft: "1rem" }}>
                <img
                  src="https://idesign-office.s3.ap-south-1.amazonaws.com/CurrencyDollarSimple.svg"
                  alt=""
                  style={{ height: "2.3rem", width: "2.3rem", marginTop: "1.2rem", marginBottom: "1rem" }}
                />
                <div style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
                  <div className={styles.heading}>Quotation</div>
                  <div className={styles.para}>Save time and improves the credibility of the interior designer by ensuring that the quotes are error-free and tailored to the client's needs.</div>
                </div>
              </div>
            </Carousel.Item>

            <Carousel.Item style={{ boxShadow: "none", borderBottom: "none" }}>
              <div className={styles.ImgDiv}>
                <img src="https://idesign-office.s3.ap-south-1.amazonaws.com/Rectangle%207464%20%283%29.svg" alt="Third slide" className={styles.imgg} />
              </div>

              <div style={{ backgroundColor: "#E9EFFB", paddingLeft: "1rem", display: "flex", flexDirection: "column" }}>
                <img src="https://idesign-office.s3.ap-south-1.amazonaws.com/LineSegments.svg" alt="" style={{ height: "2.3rem", width: "2.3rem", marginTop: "1.5rem", marginBottom: "1rem" }} />
                <div style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
                  <div className={styles.heading}>Timeline</div>
                  <div className={styles.para}>Create project schedules and timelines & helps to keep projects on track and ensures that deadlines are met.</div>
                </div>
              </div>
            </Carousel.Item>
          </Carousel>
        </div>

        {/* second div */}
        <div className={styles.second_contain}>
          <div className={styles.PersonalContent_div}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <div className={styles.personal_head}>Company Details</div>
              <button className={styles.backBtn} onClick={() => navigateTo("/personal-details")}>
                <AiOutlineArrowLeft className={styles.backArrow} />
                Go back
              </button>
            </div>

            {/* all inputs div */}
            <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
              <div style={{ display: "flex", gap: "1rem" }}>
                <TextField
                  id="outlined-basic"
                  error={error.CName}
                  label="Company Name"
                  variant="outlined"
                  className="saas-form"
                  type="text"
                  name="CName"
                  value={input.CName}
                  onChange={handleInput}
                  fullWidth
                  InputProps={{ style: { borderRadius: "12px" } }}
                />
              </div>

              <div>
                <TextField
                  id="outlined-basic"
                  error={errors.email}
                  label="Email"
                  variant="outlined"
                  className="saas-form"
                  type="text"
                  name="email"
                  value={profileData.email}
                  onChange={handleInput}
                  fullWidth
                  disabled
                  InputProps={{ style: { borderRadius: "12px" } }}
                />
              </div>

              <div>
                <TextField
                  id="outlined-basic"
                  // error={error.address}
                  label="Permanent Address"
                  variant="outlined"
                  className="saas-form"
                  type="text"
                  name="address"
                  value={profileData.address}
                  onChange={handleInput}
                  style={{ borderRadius: "12px", width: "100%" }}
                  InputProps={{ style: { borderRadius: "12px" } }}
                  disabled
                />
              </div>

              <div style={{ display: "flex", gap: "1rem", position: "relative" }}>
                <TextField
                  id="outlined-basic"
                  // error={error.city}
                  label="City"
                  variant="outlined"
                  className="saas-form"
                  type="text"
                  name="city"
                  value={profileData.city}
                  onChange={handleInput}
                  style={{ borderRadius: "12px", width: "50%" }}
                  InputProps={{ style: { borderRadius: "12px" }, inputMode: "text" }}
                  // inputProps={{  }}
                  disabled
                />

                <TextField
                  id="outlined-basic"
                  // error={error.zipCode}
                  label="Zipcode"
                  variant="outlined"
                  className="saas-form"
                  type="text"
                  name="zipCode"
                  value={profileData.pinCode}
                  onChange={handleInput}
                  style={{ width: "50%" }}
                  InputProps={{ style: { borderRadius: "12px" } }}
                  disabled
                />

                {/* <div style={{ position: "absolute", height: "3rem", width: "2rem", right: "1.2%", top: "3%", background: "#ffffff" }}></div> */}
              </div>

              <div style={{ display: "flex", gap: "1rem" }}>
                <TextField
                  id="outlined-basic"
                  error={error.gst}
                  label="GST NO."
                  variant="outlined"
                  className="saas-form"
                  type="text"
                  name="gst"
                  value={input.gst}
                  onChange={handleInput}
                  style={{ borderRadius: "12px", width: "100%" }}
                  //   InputLabelProps={{ shrink: true }} // Removes mm/dd/yy placeholder
                  InputProps={{ placeholder: "", style: { borderRadius: "12px" } }}
                />
              </div>

              <div>
                {/* <div className={error.number ? "flagPhoneNumberInputErr" : "flagPhoneNumberInput"}>
                  <PhoneInput placeholder="Enter phone number" value={input.number} onChange={(newVal) => handleInput({ target: { name: "number", value: newVal } })} />
                </div> */}

                <MuiPhoneNumber
                  fullWidth
                  variant="outlined"
                  label="Enter phone number"
                  value={profileData.phoneNumber}
                  onChange={(newVal) => handleInput({ target: { name: "number", value: newVal } })}
                  defaultCountry={"in"}
                  InputProps={{ placeholder: "", style: { borderRadius: "12px" } }}
                />
              </div>
            </div>

            <div>
              <button
                className={styles.nextBtn}
                onClick={() => {
                  Object.keys(input).forEach((elem) => {
                    if (input[elem]) {
                      // if (elem === "email") {
                      //   let valText = validationFunction(emailValidation[elem], input[elem]);
                      //   setErrors((prev) => {
                      //     return {
                      //       ...prev,
                      //       email: valText,
                      //     };
                      //   });
                      // }
                      // seterror((prev) => {
                      //   return {
                      //     ...prev,
                      //     [elem]: false,
                      //   };
                      // });
                    } else {
                      seterror((prev) => {
                        return {
                          ...prev,
                          [elem]: true,
                        };
                      });
                    }
                  });
                  if (input.CName !== "" && input.gst !== "") {
                    companyDetails();
                  }
                }}
              >
                Next
              </button>
            </div>

            <a href={`${base_url}/admin/`} className={styles.skip} style={{ textDecoration: "none" }}>
              Skip Complete Profile
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default CompanyDetails;
