import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import styles from "./createNewProjectModal.module.css";
import { mapState, projectDataCurrObj,setIsChecked,setIsChecked2,setIsChecked3, setPrevButton } from "../../../Redux/Slices/convertToProjectSlice";
import { useEffect } from "react";
import { TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import {  Checkbox, Form,  } from "antd";
import { createProjectModal, createTeam, currentLead, newProjectData } from "../../../Redux/Slices/lmsSlice";
import { BASE_URL, apiLinks } from "../../../piservices/constants";
import { FiUpload } from "react-icons/fi"
import { postReq } from "../../../piservices/apis";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import scanIcon from "../../../Assets/material-symbols_search-rounded.svg";
import CreateNewProject2 from "./CreateNewProject2";
import { GoogleMap, MarkerF } from "@react-google-maps/api";
const libraries = ["places"];

const mapContainerStyle = {
  width: "100%",
  height: "367px",
  marginTop: "5%"
};

const center = {
  lat: 28.69680310102602,
  lng: 77.22127332285639,
};


const options = {
  disableDefaultUI: true,
  zoomControl: true,
};
const apiKey = "AIzaSyAdUtzsZsgXw6tacKy2WqbPAWreWOZM-pk";

const CreateNewProjectNew = ({ currentData }) => {
  const dispatch = useDispatch();
  const [discardOpen, setDiscardOpen] = useState(false);
  const [mapModalopen, setMapModalopen] = useState(false)
  const [city, setCity] = useState("");
  const [address2, setAddress2] = useState("");
  const [markerPosition, setMarkerPosition] = useState(center);

  const createProjectOpen = useSelector((state) => state.lmsSlice.createProject);
  const currentLeadData = useSelector((state) => state.lmsSlice.currentLeadStatus);
  const [lan, setLan] = useState("");
  const [lag, setLag] = useState("");
  const [form] = Form.useForm();
  const [addressValue, setaddressValue] = useState();
  const [projectFirstName, setProjectFirstName] = useState("");
  const [projectLastName, setProjectLastName] = useState("");
  const [projectProjectName, setProjectProjectName] = useState("");
  const [projectAddress, setProjectAddress] = useState("");
  const [projectCity, setProjectCity] = useState("");
  const [projectCode, setProjectCode] = useState("");
  const [projectphone, setProjectPhone] = useState("");
  const [projectEmail, setProjectEmail] = useState("");
  const [error, setError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [errorPin, setErrorPin] = useState(false);
  const [emailCheck, setEmailCheck] = useState(false)
  const [isAddressChange, setIsAddressChange] = useState(false)
  const [currProjectIdValue, setCurrProjectIdValue] = useState("");
  const [openTeamMember, setOpenTeamMember] = useState(false);


  // const [createTeamModal, setCreateTeamModal] = useState(false)
  // const [clientId, setClientId] = useState("");
  // const [uploadedItem, setUploadedItem] = useState("");
  // const [uploadFileLink, setUploadFileLink] = useState("");
  // const [city, setCity] = useState("");
  // const [address2, setAddress2] = useState('');
  // const [lan, setLan] = useState('');
  // const [lag, setLag] = useState('');
  const checked = useSelector((state) => state.convertToProjectSlice.isChecked);
  const prev = useSelector((state) => state.convertToProjectSlice.prevBtn);
  const currProjectDataValue = useSelector(
    (state) => state.convertToProjectSlice.currProjectDataObj
  );
  const mapSt = useSelector((state) => state.convertToProjectSlice.mapState);

  // const handleChange2 = (value) => {
  //   setAddress2(value);

  // };

  // const handleSelect2 = async (value) => {
  //   try {
  //     const results = await geocodeByAddress(value);
  //     const latLng = await getLatLng(results[0]);

  //     setAddress2(results[0].formatted_address)
  //     setLan(latLng.lat)

  //     setLag(latLng.lng)
  //   } catch (error) {
  //     console.error('Error:', error);
  //   }

  // };
  // const handleSubmit = async () => {
    // event.preventDefault();


    // fetch(
    //   `https://maps.googleapis.com/maps/api/geocode/json?address=${address2}&key=AIzaSyAdUtzsZsgXw6tacKy2WqbPAWreWOZM-pk`
    // )
    //   .then((response) => response.json())
    //   .then((data) => {
    //     const { lat, lng } = data.results[0].geometry.location;
    //     setMarkerPosition({ lat, lng });
    //     localStorage.setItem("address", data.results[0].geometry.location);
    //     localStorage.setItem("Long & Lat",{ lat, lng });
    //   })
    //   .catch((error) => console.error("Error:", error));
  //   const res = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${address2}&key=AIzaSyAdUtzsZsgXw6tacKy2WqbPAWreWOZM-pk`)

  //   // console.log("@@@@@@@@", res?.data?.results[0]?.formatted_address)
  //   const { lat, lng } = res?.data?.results[0]?.geometry?.location;
  //   setMarkerPosition({ lat, lng });
  //   localStorage.setItem("address", res?.data?.results[0]?.geometry.location);
  //   localStorage.setItem("Long & Lat", { lat, lng });


  // };



  // let cityName = `${addressValue ? addressValue : ""},${city}`


  // const inputHandlerAddress = (event) => {
  //   setaddressValue(event.target.value);
  // };

  // email validation
  // const emailValidation = () => {
  //   return /\S+@\S+\.\S+/.test(details.email);
  // };

  // useEffect(() => {
  //   const mailE = emailValidation();
  //   if (!mailE) {
  //     setEmailErr("Invalid Email");
  //   } else {
  //     setEmailErr("");
  //   }
  // }, [details.email]);

  // useEffect(() => {
  //   localStorage.setItem("address", `${addressValue ? addressValue : ""},${city}`);

  //   // setAddressLS(addressValue,city)
  // }, [city]);

  // const onEditProject = async (formData) => {
  //   const projRes = await postReq(
  //     `${apiLinks.pmt
  //     }/api/projects/edit-location?projectId=${localStorage.getItem(
  //       "projectId"
  //     )}`,
  //     {
  //       id: localStorage.getItem("projectId"),
  //       name: formData.projName,
  //       address: addressValue,
  //       city: formData.city,
  //       landmark: formData.landmark,
  //       phn: formData.phone,
  //       userId: localStorage.getItem("userId"),
  //     }
  //   );
    // const editClient = await postReq(
    //   `${constants.B2B_BASE}/user/editClient?id=${localStorage.getItem(
    //     "clientId"
    //   )}`,
    //   {
    //     fullName: formData.cliName,
    //     phoneNumber: formData.phone,

    //     address: addressValue,
    //   }
    // );
  //   if ((projRes) && (!projRes.error)) {



  //   }
  // }
  // const [previousData, setPreviousData] = useState("");
  // const getPriousData = async () => {
  //   const res = await axios.get(`${apiLinks.pmt}/api/projects/get-project-byId?projectId=${localStorage.getItem("projectId")}`)
  //   setPreviousData(res.data)
  // }

  // useEffect(() => {
  //   if (localStorage.getItem("prev")) {

  //     getPriousData()
  //   }
  // }, [localStorage.getItem("prev")]);

  // const onformFinish = (formD) => {
  //   // createProject1(formD);
  //   dispatch(newProjectData(formD))
  //   setMapModalopen(true)
  //   dispatch(createProjectModal(false))
  // };
  // useEffect(() => {
  //   if (localStorage.getItem("prev")) {

  //     getPriousData()
  //   }
  // }, [localStorage.getItem("prev")]);
  // const handleFileUpload = (e) => {
  //   let uploadIt = e.target.files;
  //   let file = new FormData();
  //   file.append("bucketName", "idesign-quotation");
  //   file.append("files", uploadIt[0]);
  //   upload(file);
  //   let x = e.target.value.split("\\");
  //   setUploadedItem(x[x.length - 1]);
  // };
  // const upload = async (data) => {
  //   return await axios
  //     .put(`${apiLinks.S3_BUCKET}/api/upload/`, data)
  //     .then((re) => setUploadFileLink(re.data.locations[0]))
  //     .catch((err) => console.log(err));
  // };

  // const handleNewProjectClose = () => {
  //   setDiscardOpen(true)
  // }
  // const [discardOpen, setDiscardOpen] = useState(false);
  // const [projectName, setProjectName] = useState(null);
  // const handleProjectName = (e) => {
  //   if(e.target.value?.length<=50){
  //     console.log('pname', e.target.value)
  //     setProjectName(e.target.value)
  //   }
    
  // }
  // const deleteCreatedProject = async () => {
  //   const res = await postReq(
  //     `${apiLinks.pmt
  //     }/api/projects/edit-location?projectId=${localStorage.getItem(
  //       "projectId"
  //     )}`,
  //     {

  //       isDeleted: true,

  //     }
  //   );
  //   if (res) {
  //     console.log(res)
  //   }
  // }
  // const [discardModalopen, setDiscardModalopen] = useState(false)
  // const handleDiscardClose = () => {


  //   dispatch(createProjectModal(false))
  //   dispatch(currentLead(""))
  //   setDiscardModalopen(false)
  //   deleteCreatedProject()
  //   form.resetFields();


  // }

  // // Map
  // const [markerPosition, setMarkerPosition] = useState(center);

  // const handleMarkerDrag = (e) => {
  //   const newPosition = {
  //     lat: e.latLng.lat(),
  //     lng: e.latLng.lng(),
  //   };

  //   setMarkerPosition(newPosition);
  //   getLocationFromLatLng()
  // };
  // const changeAddress = async () => {
  //   const res = await postReq(
  //     `${apiLinks.pmt
  //     }/api/projects/edit-location?projectId=${localStorage.getItem(
  //       "projectId"
  //     )}`,
  //     {
  //       location: address2

  //     }
  //   );
  // }
  // const getLocationFromLatLng = async () => {

  //   const response = await axios.get(
  //     `https://maps.googleapis.com/maps/api/geocode/json?latlng=${markerPosition?.lat},${markerPosition?.lng}&key=AIzaSyAdUtzsZsgXw6tacKy2WqbPAWreWOZM-pk`
  //   );

  //   if (response.data.results.length > 0) {
  //     const location = response.data.results[0].formatted_address;
  //     setAddress2(location)

  //   }
  // };
  useEffect(() => {
    handleSubmit()
  }, [lan,lag])
  let projectDataObj = {
    firstName: "",
    lastName: "",
    projectName: "",
    address: "",
    city: "",
    code: "",
    phnNum: "",
    email: "",
    document: "",
    documentLink: "",
    emailCheck:false,
  };
  const handleNewProjectClose = () => {
    setDiscardOpen(true);
  };
  const getLocationFromLatLng = async () => {
    const response = await axios.get(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${markerPosition?.lat},${markerPosition?.lng}&key=AIzaSyAdUtzsZsgXw6tacKy2WqbPAWreWOZM-pk`
    );

    if (response.data.results.length > 0) {
      const location = response.data.results[0].formatted_address;
      setAddress2(location);
    }
  };
  const handleMarkerDrag = (e) => {
    const newPosition = {
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    };

    setMarkerPosition(newPosition);
    getLocationFromLatLng();
  };



  const onformFinish = (formD) => {
    // if (prev === true) {
    //   onEditProject(formD);
    // } else 
    if (
      (projectProjectName == "" ||
        projectProjectName == undefined ||
        projectFirstName == "" ||
        projectFirstName == undefined ||
        projectLastName == "" ||
        projectLastName == undefined ||
        projectAddress == "" ||
        projectAddress == undefined ||
        projectCity == "" ||
        projectCity == undefined ||
        projectCode == "" ||
        projectCode == undefined ||
        projectphone == "" ||
        projectphone == undefined ||
        error ||
        errorPin ||
        emailError ||
        projectEmail == "" ||
        projectEmail == undefined)
    ) {
      alert("Fill all the details correctly");
    }
    else {
      // createProject1(formD);
      dispatch(mapState(true));
      
    }
    // dispatch(mapState(true));
    // dispatch(newProject(formD));
  };
  const deleteCreatedProject = async () => {
    const res = await postReq(
      `${apiLinks.pmt}/api/projects/edit-location?projectId=${currProjectIdValue}`,
      {
        isDeleted: true,
      }
    );
    if (res) {
      console.log(res);
    }
  };
  const handleChange2 = (value) => {
    let newObj = JSON.parse(JSON.stringify(currProjectDataValue))
    newObj["address"] = value 

    setAddress2(value);
    setIsAddressChange(true)
    dispatch(projectDataCurrObj(newObj));

  };
  const handleSelect2 = async (value) => {
    try {
      let newObj = JSON.parse(JSON.stringify(currProjectDataValue)) 
      
      const results = await geocodeByAddress(value);
      const latLng = await getLatLng(results[0]);
      setAddress2(results[0].formatted_address);
      newObj["address"] = results[0].formatted_address 
      dispatch(projectDataCurrObj(newObj));
      // dispatch(projectDataCurrObj({...projectDataObj,address:results[0].formatted_address}));
      setLan(latLng.lat);
      setLag(latLng.lng);

    } catch (error) {
      console.error("Error:", error);
    }

  };
  const handleDiscardClose = () => {
    setDiscardOpen(false);
    // dispatch(isLeadProject(false))
    dispatch(projectDataCurrObj());
    setProjectFirstName("")
    setProjectLastName("")
    setProjectProjectName("")
    setProjectAddress("")
    setProjectCity("")
    setProjectCode("")
    setProjectPhone("")
    setProjectEmail("")
    setErrorPin(false)
    setError(false)
    setEmailError(false)
    dispatch(projectDataCurrObj(""));
    // dispatch(existClientModal(false));
    // deleteCreatedProject();
    dispatch(setIsChecked(false));
    // dispatch(setProjectReload(true));
    form.resetFields();
  };
  const handleSubmitProject = () => {
    projectDataObj.firstName = projectFirstName;
    projectDataObj.lastName = projectLastName;
    projectDataObj.projectName = projectProjectName;
    projectDataObj.address = projectAddress;
    projectDataObj.city = projectCity;
    projectDataObj.code = projectCode;
    projectDataObj.phnNum = projectphone;
    projectDataObj.email = projectEmail;
    projectDataObj.emailCheck =emailCheck

    dispatch(projectDataCurrObj(projectDataObj));
    onformFinish();
  };
  const handleSubmit = async () => {

    const res = await axios.get(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${address2}&key=AIzaSyAdUtzsZsgXw6tacKy2WqbPAWreWOZM-pk`
    );

    const { lat, lng } = res?.data?.results[0]?.geometry?.location;
    setMarkerPosition({ lat, lng });
    localStorage.setItem("address", res?.data?.results[0]?.geometry.location);
    localStorage.setItem("Long & Lat", { lat, lng });
  };
  const changeAddress = async () => {
    if (isAddressChange) {
      const res = await postReq(
        `${apiLinks.pmt
        }/api/projects/edit-location?projectId=${localStorage.getItem(
          "projectId"
        )}`,
        {
          location: address2,
        }
      );
    }
  };
  useEffect(() => {
    setProjectFirstName(currProjectDataValue?.firstName);
    setProjectLastName(currProjectDataValue?.lastName);
    setProjectProjectName(currProjectDataValue?.projectName);
    setProjectAddress(currProjectDataValue?.address);
    setProjectCity(currProjectDataValue?.city);
    setProjectCode(currProjectDataValue?.code);
    setProjectPhone(currProjectDataValue?.phnNum);
    setProjectEmail(currProjectDataValue?.email);
  }, [currProjectDataValue]);

  const handlePhoneNumberChange = (event) => {
    console.log('phonenumberrr',event.target.value);
    const inputValue = event.target.value.replace(/[^0-9]/g, '');
    if (event.target.value?.length <= 15) {

      setProjectPhone(inputValue);

      if (/^\d+$/.test(inputValue) && inputValue.length >= 10 && inputValue.length <= 10) {
        setProjectPhone(inputValue);
        setError(false);
      } else {
        setError(true);
        setProjectPhone(inputValue);
      }
    }
  };
  const projectFirstNameHandle = (e) => {
    if (e.target.value?.length <= 50  && /^[a-zA-Z\s]*$/.test(e.target.value)) {
      setProjectFirstName(e.target.value)
    }
  }
  const projectLastNameHandle = (e) => {
    if (e.target.value?.length <= 50  && /^[a-zA-Z\s]*$/.test(e.target.value)) {
      setProjectLastName(e.target.value)
    }
  }
  const projectNameHandle = (e) => {
    if (e.target.value?.length <= 50) {
      setProjectProjectName(e.target.value)
    }
  }
  const handlePinCodeChange = (event) => {
    const inputValue = event.target.value.replace(/[^0-9]/g, '');
    if (event.target.value?.length <= 6) {
      setProjectCode(inputValue);

      if (/^\d+$/.test(inputValue) && inputValue.length >= 6 && inputValue.length <= 6) {
        setProjectCode(inputValue);
        setErrorPin(false);
      } else {
        setErrorPin(true);
        setProjectCode(inputValue);
      }
    }
  };
  const handleEmailChange = (event) => {
    const inputValue = event.target.value;
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,100}$/i;
    if (emailPattern.test(inputValue)) {
      setProjectEmail(inputValue);
      setEmailError(false);
    } else {
      setEmailError(true);
      setProjectEmail(inputValue);
    }
  };

  return (
    <React.Fragment>
     {openTeamMember && <CreateNewProject2 leadInfo={currentData}/>}
    <Modal
      show={checked}
      onHide={() => setDiscardOpen(true)}
      backdrop="static"
      keyboard={false}
      centered
      size="lg"
    >
      <Modal.Header style={{ borderBottom: "none" }}>
        <Modal.Title>
          <div className={styles.createProjectModalHeader}>
            <span>Create Project</span>
            <div className={styles.closeBtn}>
              <button
                className={styles.createModalClose}
                onClick={handleNewProjectClose}
              >
                <span
                  style={{
                    color: "#C9C9C9",
                    fontWeight: "600",
                    fontSize: "1rem",
                  }}
                >
                  Cancel{" "}
                </span>
              </button>
              <button
                className={styles.createModalSave}
                onClick={handleSubmitProject}
              >
                <span
                  style={{
                    color: "#FFF",
                    fontWeight: "600",
                    fontSize: "1rem",
                  }}
                >
                  Next{" "}
                </span>
              </button>
            </div>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ width: "50.5rem" }}>
        <div className={styles.formName}>
          <TextField
            style={{ width: "22.1875rem" }}
            id="outlined-basic"
            label="First Name*"
            variant="outlined"
            isRequired={true}
            value={projectFirstName}
            onChange={(e) => { projectFirstNameHandle(e) }}
          />

          <TextField
            style={{ width: "22.1875rem" }}
            id="outlined-basic"
            label="Last Name*"
            variant="outlined"
            isRequired={true}
            value={projectLastName}
            onChange={(e) => projectLastNameHandle(e)}
          />
        </div>
        <div className={styles.formName}>
          <TextField
            style={{ width: "46.5rem" }}
            id="outlined-basic"
            label="Project Name*"
            variant="outlined"
            isRequired={true}
            value={projectProjectName}
            onChange={(e) => projectNameHandle(e)}
          />
        </div>
        <div className={styles.formName}>
          <TextField
            style={{ width: "46.5rem" }}
            id="outlined-basic"
            label="Address*"
            variant="outlined"
            isRequired={true}
            value={projectAddress}
            onChange={(e) => setProjectAddress(e.target.value)}
          />
        </div>
        <div className={styles.formName}>
          <TextField
            style={{ width: "22.1875rem" }}
            id="outlined-basic"
            label="City*"
            variant="outlined"
            isRequired={true}
            value={projectCity}
            onChange={(e) => setProjectCity(e.target.value)}
          />

          <TextField
            style={{ width: "22.1875rem" }}
            id="outlined-basic"
            label="Postal Code*"
            variant="outlined"
            isRequired={true}
            value={projectCode}
            error={errorPin}
            helperText={errorPin ? 'Invalid Pin Code' : ''}
            onChange={(e) => handlePinCodeChange(e)}
            InputProps={{
              inputMode: 'numeric',

            }}
          />
        </div>
        <div className={styles.formName}>
          <TextField
            style={{ width: "22.1875rem" }}
            id="outlined-basic"
            label="Contact No."
            variant="outlined"
            value={projectphone}
            error={error}
            helperText={error ? 'Invalid phone number' : ''}
            onChange={(e) => handlePhoneNumberChange(e)}
            InputProps={{
              inputMode: 'numeric',
            }}
          />

          <TextField
            style={{ width: "22.1875rem" }}
            id="outlined-basic"
            label="Email Address*"
            variant="outlined"
            isRequired={true}
            value={projectEmail}
            error={emailError}
            helperText={emailError ? 'Invalid email address' : ''}
            onChange={(e) => handleEmailChange(e)}
          />
        </div>

        <div className={styles.formCheckbox}>
          <Checkbox value={emailCheck} onChange={() => setEmailCheck(!emailCheck)} />
          <p style={{ display: "flex", marginBottom: "0%", marginLeft: "2%" }}>Send email to client</p>
        </div>
        {/* <div className={styles.formName}>
       <div className={styles.uploadAgreement}> <a
                          style={{
                            color: "#0084EF",
                            height: "25px",
                            width: "auto",
                            borderSpacing: "10px",
                            textDecoration: "none",
                            cursor: "pointer",
                          }}
                          target="_blank"
                          href={uploadFileLink}
                        >
                          {uploadedItem}
                        </a></div>
       <div className={styles.uploadText}>   <input
                      type="file"
                      accept=".doc,.docx,.png,.jpg,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                      onChange={(e) => handleFileUpload(e)}
                      multiple
                    /> <span style={{color:"#0084EF", cursor:"pointer"}}>Upload</span></div>
                </div> */}
      </Modal.Body>
    </Modal>

    <Modal
      title={false}
      show={discardOpen}
      centered
      width={300}
      bodyStyle={{ height: 60 }}
      closable={false}
    >
      <h6 style={{ padding: "3%" }}>Do you want to discard the project ?</h6>
      <Modal.Footer>
        <div className={styles.discardCloseBtn}>
          <button
            className={styles.createModalSave}
            onClick={handleDiscardClose}
          >
            <span
              style={{
                color: "#FFF",
                fontWeight: "600",
                fontSize: "1rem",
              }}
            >
              Yes{" "}
            </span>
          </button>
          <button
            className={styles.createModalClose}
            onClick={() => setDiscardOpen(false)}
          >
            <span
              style={{
                color: "#C9C9C9",
                fontWeight: "600",
                fontSize: "1rem",
              }}
            >
              No{" "}
            </span>
          </button>
        </div>
      </Modal.Footer>
    </Modal>
    {/* mapSt */}
    {mapSt && (
      <Modal
        show={true}
        size="lg"
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header style={{ borderBottom: "none" }}>
          <Modal.Title>
            <div className={styles.createProjectModalHeader}>
              <span>Set Location</span>
              <div className={styles.closeBtn}>
                <button
                  className={styles.createModalClose}
                  onClick={() => (
                    dispatch(mapState(false)),
                    dispatch(setIsChecked(true)),
                    localStorage.setItem("prev", "yes"),
                    dispatch(setPrevButton(true))
                  )}
                >
                  <span
                    style={{
                      color: "#C9C9C9",
                      fontWeight: "600",
                      fontSize: "1rem",
                    }}
                  >
                    Previous{" "}
                  </span>
                </button>
                <button
                  className={styles.createModalSave}
                  onClick={() => {
                    setOpenTeamMember(true);
                    dispatch(mapState(false));
                    dispatch(setIsChecked(false));
                    dispatch(setIsChecked2(true));
                    localStorage.setItem("address", city);
                    // changeAddress();
                   
                  }}
                >
                  <span
                    style={{
                      color: "#FFF",
                      fontWeight: "600",
                      fontSize: "1rem",
                    }}
                  >
                    Next{" "}
                  </span>
                </button>
              </div>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
          <PlacesAutocomplete
              value={address2}
              onChange={handleChange2}
              onSelect={handleSelect2}
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading,
              }) => (
                <div>
                
                  <div style={{ display: "flex" }}>
                    {/* <input
                      style={{
                        padding:""
                      }}
                      {...getInputProps({
                        placeholder: "Search your location",
                      })}
                      value={address2}
                    /> */}
          <div className={styles.formName}>
            <TextField
              style={{ width: "46.5rem" }}
              id="outlined-basic"
              label="Search for Location"
              variant="outlined"
              isRequired={true}
              value={address2}
              {...getInputProps({
                placeholder: "Search your location",
              })}
            />
          </div>
                    <img
                      src={scanIcon}
                      alt=""
                      style={{ marginLeft: "-5%", height:"100%", paddingTop:"2.5%",zIndex:"99", color: "#888888" , cursor:"pointer"}}
                      onClick={handleSubmit}
                    />
                    {/* <button
                      className={styles.addressBTN}
                      type="submit"
                      onClick={handleSubmit}
                    >
                      Search
                    </button> */}
                  </div>
                  <div
                    style={{
                      marginTop: "-2%",
                      marginLeft:"1%",
                      position: "fixed",
                      zIndex: "999",
                      width: "46.5rem",
                      
                    }}
                  >
                    {loading ? <div>Loading...</div> : null}
                    {suggestions.map((suggestion) => {
                      const style = {
                        backgroundColor: suggestion.active ? "#FAFAFA" : "#fff",
                        cursor: "pointer",
                        // marginBottom:"2%",
                        padding: "2%",
                        borderBottom: "1px solid #DFDFDF",
                      };
                      return (
                        <div
                          key={suggestion.placeId}
                          {...getSuggestionItemProps(suggestion, { style })}
                        >
                          {suggestion.description}
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </PlacesAutocomplete>
            <GoogleMap
              mapContainerStyle={mapContainerStyle}
              center={markerPosition}
              zoom={15}
              options={options}
            >
              <MarkerF
                position={markerPosition}
                draggable={true}
                onDragEnd={handleMarkerDrag}
              />
            </GoogleMap>
          </>
        </Modal.Body>
      </Modal>
    )}
  </React.Fragment>

    // <React.Fragment>

    //   <Modal
    //     open={createProjectOpen}
    //     footer={false}
    //     onCancel={() => setDiscardModalopen(true)}
    //     maskClosable={false}
    //     width={500}
    //   // bodyStyle={{padding:-20}}
    //   // bodyStyle={{padding:"0px!important"}}
    //   >
    //     <h3 style={{ backgroundClip: 'border-box', width: '100%' }}>Create project</h3>
    //     <Form
    //       name="basic"
    //       // labelCol={{
    //       //   span: 6,
    //       // }}
    //       // wrapperCol={{
    //       //   span: 16,
    //       // }}
    //       style={{
    //         maxWidth: 800,
    //         marginTop: "1.5rem",
    //       }}
    //       initialValues={{
    //         remember: false,
    //       }}
    //       form={form}
    //       onFinish={onformFinish}
    //       // onFinishFailed={onFinishFailed}
    //       autoComplete="off"
    //     >
    //       <Form.Item
    //         name="projName"

    //         rules={[
    //           {
    //             required: true,
    //             message: "Please Enter Project Name!",

    //           },
    //           {
    //             max: 50,
    //             message: "Value should be less than 50 characters",
    //           }
    //         ]}
    //       >
    //         <Input name="projName" className={styles.inputBox} placeholder="Enter Project's Name" onChange={(e) => handleProjectName(e)} />
    //       </Form.Item>
    //       <Form.Item
    //         name="cliName"
    //         initialValue={currentData.name}
    //         rules={[
    //           {
    //             required: true,
    //             message: "Please Enter Client Name!",
    //           },
    //           {
    //             max: 50,
    //             message: "Value should be less than 50 characters",
    //           }
    //         ]}
    //       >
    //         <Input name="cliName" className={styles.inputBox} placeholder="Enter Client's Name" />
    //       </Form.Item>
    //       <Form.Item
    //         name="location"
    //         initialValue={currentData.address}
    //         rules={[
    //           {
    //             required: true,

    //           },
    //           {
    //             max: 50,
    //             message: "Value should be less than 50 characters",
    //           }
    //         ]}
    //       >
    //         <Input
    //           className={styles.inputBox}
    //           placeholder="Enter Address"
    //           value={addressValue}
    //           onChange={inputHandlerAddress}
    //           name="location"
    //         />

    //       </Form.Item>
    //       <Form.Item
    //         name="city"
    //         rules={[
    //           {
    //             required: true,
    //             message: "Enter City!",
    //           },
    //           {
    //             max: 60,
    //             message: "Value should be less than 50 characters",
    //           }
    //         ]}
    //       //      
    //       >
    //         <Input className={styles.inputBox} placeholder="Enter City" />
    //       </Form.Item>
    //       <Form.Item
    //         name="landmark"
    //         rules={[
    //           {
    //             message: "Please Enter Landmark!",
    //           },
    //           {
    //             max: 50,
    //             message: "Value should be less than 50 characters",
    //           }
    //         ]}
    //       >
    //         <Input className={styles.inputBox} placeholder="Enter Landmark" />
    //       </Form.Item>
    //       <Form.Item
    //         name="phone"
    //         initialValue={currentData.phoneNumber}
    //         rules={[
    //           {
    //             pattern: new RegExp(
    //               /^(?=.*(\d)(?=\d)(?!\1))\d{10}$/
    //             ),
    //             message: "Please Enter valid Phone Number!",
    //           },
              
    //         ]}
    //       >
    //         <Input  onKeyDown={(e) =>
    //                       ['e'].includes(e.key) &&
    //                       e.preventDefault()
    //                     } className={styles.inputBox} id="phone" placeholder="Enter Phone Numbers" type="number" />
    //       </Form.Item>
    //       <Form.Item
    //         name="email"
    //         initialValue={currentData.email}
    //         rules={[
    //           {
    //             required: true,
    //             pattern: new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,}$/g),
    //             message: "Please Enter valid Email Id!",
    //           },
    //         ]}
    //       >
    //         <Input className={styles.inputBox} placeholder="Enter Email Address" />
    //       </Form.Item>
    //       <Form.Item
    //         wrapperCol={{
    //           offset: 19,
    //           span: 18,
    //         }}
    //       >
    //         <div className={styles.createNewFooter}>
    //           <div className={styles.uploadProof}>
    //             <div style={{ display: "flex", cursor: "pointer" }}>
    //               <div className={styles.upload}>
    //                 <input
    //                   type="file"
    //                   accept=".doc,.docx,.png,.jpg,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    //                   onChange={(e) => handleFileUpload(e)}
    //                   multiple
    //                 />
    //                 <div style={{ cursor: "pointer" }}>
    //                   <FiUpload color="#0084EF" />
    //                 </div>
    //               </div>
    //               <div>
    //                 {uploadedItem ? (
    //                   <label
    //                     htmlFor=""
    //                     style={{
    //                       color: "#0084EF",
    //                       height: "19px",
    //                       paddingTop: "8px"
    //                     }}
    //                   >
    //                     <a
    //                       style={{
    //                         color: "#0084EF",
    //                         height: "25px",
    //                         width: "auto",
    //                         borderSpacing: "10px",
    //                         textDecoration: "none",
    //                       }}
    //                       target="_blank"
    //                       href={uploadFileLink}
    //                     >
    //                       {uploadedItem}
    //                     </a>
    //                   </label>
    //                 ) : (
    //                   <label
    //                     htmlFor=""
    //                     style={{
    //                       color: "#0084EF",
    //                       height: "19px",
    //                       paddingTop: "8px"
    //                     }}
    //                   >
    //                     Upload Agreement
    //                   </label>
    //                 )}
    //               </div>
    //             </div>
    //             {/* {uploadedItem && (
    //               <>
    //                 <p style={{ marginLeft: "8px" }}> File Name : {""}
    //                   <a
    //                     style={{
    //                       color: "#5255A4",
    //                       height: "25px",
    //                       width: "auto",
    //                       borderSpacing: "10px",
    //                       textDecoration: "none",
    //                     }}
    //                     target="_blank"
    //                     href={uploadFileLink}
    //                   >
    //                     {uploadedItem}
    //                   </a>
    //                 </p>
    //               </>
    //             )} */}
    //           </div>

    //           <Button
    //             type="primary"
    //             htmlType="submit"
    //             //  className={!details.cliName || !details.projName || !details.city || !details.landmark || !details.email || !addressValue || emailErr ? styles.nextBtn2 : styles.nextBtn}
    //             //  disabled={!details.cliName || !details.projName || !details.city || !details.landmark || !details.email || !addressValue || emailErr}
    //             style={{ background: "#0084EF", marginLeft: "4vw" }}
    //             size="large"
    //           >
    //             Next
    //           </Button>
    //         </div>
    //       </Form.Item>
    //     </Form>
    //   </Modal>


    //   <Modal
    //     title={false}
    //     open={discardModalopen}
    //     onOk={handleDiscardClose}
    //     onCancel={() => setDiscardModalopen(false)}
    //     centered
    //     width={300}
    //     bodyStyle={{ height: 60 }}
    //     closable={false}
    //   >
    //     <h6>
    //       Do you want discard the project ?
    //     </h6>
    //   </Modal>

    //   <Modal
    //     title={false}
    //     open={mapModalopen}
    //     footer={null}
    //     centered
    //     width={500}

    //     bodyStyle={{ height: 550 }}
    //     closable={false}

    //   >
    //     <>
    //       <PlacesAutocomplete value={address2} onChange={handleChange2} onSelect={handleSelect2}>
    //         {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
    //           <div>
    //             <div style={{ fontSize: "20px", fontWeight: "600", color: "black", fontFamily: "Inter", borderBottom: "1px solid #DFDFDF", marginLeft: "-5%", width: "110%", marginBottom: "6%", paddingLeft: "5%" }}> Set Location</div>
    //             <div style={{ display: "flex" }}>
    //               <input style={{ width: "80%", borderRadius: "4px", border: "1px solid #DFDFDF", paddingLeft: "12px", background: "#F0F0F0", height: "5vh", marginRight: "5%" }} {...getInputProps({ placeholder: 'Search your location' })} value={address2} />
    //               {/* <img src={scanIcon} alt="" style={{marginLeft:"-10%", color:"#888888"}}/> */}
    //               <button className={styles.addressBTN} type="submit" style={{ background: '#0084EF' }} onClick={handleSubmit}>Search</button>
    //             </div>
    //             <div style={{ marginTop: "0%", position: "fixed", zIndex: "999", width: "25vw" }}>
    //               {loading ? <div>Loading...</div> : null}
    //               {suggestions.map((suggestion) => {
    //                 const style = {
    //                   backgroundColor: suggestion.active ? '#FAFAFA' : '#fff',
    //                   cursor: "pointer",
    //                   // marginBottom:"2%",
    //                   padding: "2%",
    //                   borderBottom: "1px solid #DFDFDF"
    //                 };
    //                 return (
    //                   <div key={suggestion.placeId} {...getSuggestionItemProps(suggestion, { style })} >
    //                     {suggestion.description}
    //                   </div>
    //                 );

    //               })}
    //             </div>
    //           </div>
    //         )}

    //       </PlacesAutocomplete>
    //       <GoogleMap
    //         mapContainerStyle={mapContainerStyle}
    //         center={markerPosition}


    //         zoom={10}
    //         options={options}
    //       >
    //         <Marker position={markerPosition} draggable={true} onDragEnd={handleMarkerDrag}
    //         />
    //       </GoogleMap>
    //       <div style={{ position: "absolute", bottom: "2%", right: "5%", display: "flex" }}>
    //         <Button style={{ marginRight: "5%", width: "100px", height: "39px", fontSize: "16px", fontWeight: "500", color: "#0084EF" }} onClick={() => (dispatch(createProjectModal(true)), localStorage.setItem("prev", "yes"), setMapModalopen(false))}>
    //           Previous
    //         </Button>
    //         <Button style={{ background: "#0084EF", color: "white ", width: "100px", height: "39px", fontSize: "16px", fontWeight: "500" }} onClick={() => {

    //           localStorage.setItem("address", city);

    //           setMapModalopen(false)
    //           dispatch(createTeam(true))
    //           setCreateTeamModal(true)
    //         }}>
    //           Next
    //         </Button>
    //       </div>
    //     </>
    //   </Modal>
    //   {createTeamModal && <CreateNewProject2  />}setCreateTeamModal = {setCreateTeamModal} setMapModalopen={setMapModalopen} data={currentData}
    // </React.Fragment>
  );
};

export default CreateNewProjectNew;
