import React, { useContext, useEffect, useState } from "react";
import "../index.css";
import Select from 'react-select';
import desquoContext from "../../../picontext/DashBoard/desquoContext";
import { getReq, postReq } from "../../../piservices/apis";
import expf, { apiLinks, base_url } from "../../../piservices/constants";
import {
    baseUrl,
    formateDate,
    valueToLeadStatus,
} from "../../../piservices/compCommon";
import PiAlert from "../../PiAlert/PiAlert";
import { BsCheck2, BsTelephone } from "react-icons/bs";
import { AiOutlineMail } from "react-icons/ai";
import { HiOutlineClipboardList } from "react-icons/hi";
import { motion } from "framer-motion";
import { getToken } from "../../../piservices/authService";
import { Button, Modal, notification, Form } from "antd";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { setIsChecked, setEditLead } from "../../../Redux/Slices/lmsSlice";
import { useParams } from "react-router-dom";

const UnAssignedSidebar = () => {
    let { tab } = useParams();
    const [onEditLead, setEditLead] = useState(false)
    const context = useContext(desquoContext);
    const [assignId, setAssignId] = useState()
    const {
        activeLead,
        setActiveLeadState,
        setLeads,
    } = context;
    const {
        name,
        phoneNumber,
        assignedTo,
        email,
    } = activeLead;
    const [assignToValue, setAssignToValue] = useState(assignedTo)

    const dispatch = useDispatch()
    const [teamLeadArr, setTeamLeadArr] = useState([]);
    const [form] = Form.useForm()
    const [userData, setUserData] = useState({});
    const [isAssignModal, setIsAssignModal] = useState(false);
    const AssignshowModal = () => {
        setIsAssignModal(true);
    };
    const AssignCancelModal = () => {
        setIsAssignModal(false);
    };
    const apifetch = async () => {
        const res = await getReq(
            expf.dev + "/user/newLeadsData?pageNo=0" + (tab > 0 ? "&type=" + tab : "")
        );
        if (res && !res.error) {
            setLeads(res?.data?.data);
        }
    };
    const handleAssignChange = (value) => {
        setAssignId(value.value);
        setAssignToValue({
            fullName: value?.label,
            _id: value?.value
        })
    }
    const getUserbyId = async (id) => {
        const res = await getReq(`${apiLinks.crm}/user/getUserById?userId=${id}`);
        if (res && !res.error) {
            setUserData(res?.data?.data);
        } else {
            console.log(res.error);
        }
    };
    useEffect(() => {
        getUserbyId(localStorage.getItem("userId"));
    }, []);
    const getTeamLeads = async () => {
        const res = await getReq(`${expf.proapi}/api/enterprise/get-team-leaders?userId=${localStorage.getItem("userId")}&access=1`);
        if (res && !res.error) {
            setTeamLeadArr(res.data.data);
        } else {
            console.log(res.error);
        }
    };
    const assignLead = async (formData) => {
        const res = await postReq(
            `${expf.proapi}/user/edit-lead?id=${activeLead?._id
            }&userId=${localStorage.getItem("userId")}`,
            {
                // leadId: activeLead?._id,
                assignedTo: assignId,
            }
        );
        if (res && !res.error) {
            console.log("success");
            notification.success({
                message: res?.message || "Assign Lead Successfully",
                duration: 1,
            });
            form.resetFields();
            AssignCancelModal();
            setAssignId()
            // dispatch(setEditLead(true));
            apifetch();
            await setActiveLeadState({ ...activeLead, assignedTo: assignToValue });
        } else {
            console.log(res.error);
            notification.error({
                message: res?.message || "Oops Something Went Wrong",
                duration: 1,
            });
        }
    };
    useEffect(() => {
        getTeamLeads();
    }, []);
    return (
        <div className="br-5 mv-br-0 mv-vh-50 " style={{ width: window.screen.width > 1024 ? "60%" : "95%", position: "sticky", top: "0px", border: '1px solid #EDEDED' }}>
            <div
                className="pt-3 pt-md-2 px-3 px-md-2 py-2"
                style={{
                    borderRadius: '8px 8px 0px 0px',
                    background: 'linear-gradient(90deg, #0084EF 0%, #0063B2 100%)',
                    color: "#ffffff",
                }}
            >
                <div className="d-flex justify-content-between mb-2 align-items-center">
                    <div className="d-flex align-items-center w-100 justify-content-between mt-2">
                        <div className="d-flex align-items-center w-100 justify-content-between mt-2">
                            <h6 className="fw-bold mb-0">{name}</h6>
                        </div>
                    </div>
                </div>
                {phoneNumber && (
                    <div className="d-flex align-items-center">
                        {/* <img src={vector4} alt="" width={12} height={12} className="" /> */}
                        <BsTelephone fontSize={12} />
                        <span
                            style={{ color: "#ffffff" }}
                            className="pfs-12 mv-pfs-14 ms-2"
                        >
                            {phoneNumber}
                        </span>
                    </div>
                )}
                {email && (
                    <div className="d-flex align-items-center">
                        {/* <img src={vector0} alt="" width={12} height={12} className="" /> */}
                        <AiOutlineMail />
                        <span
                            style={{ color: "#ffffff" }}
                            className="pfs-12 mv-pfs-14 ms-2"
                        >
                            {email}
                        </span>
                    </div>
                )}
            </div>
            <div className="px-3 pb-2">
                <div className="mt-2">
                    <p className="fw-bold mb-0">
                        Assign To
                    </p>
                </div>
                <div className="mt-3"
                    style={{
                        display: "flex",
                        flexFlow: "column",
                        justifyContent: "space-between",
                    }}
                >
                    <Select
                        className="basic-single"
                        classNamePrefix="select"
                        // defaultValue={colourOptions[0]}
                        // isDisabled={isDisabled}
                        // isLoading={isLoading}
                        isClearable={true}
                        // isRtl={isRtl}
                        isSearchable={true}
                        onChange={handleAssignChange}
                        name="assignTo"
                        options={(
                            [...teamLeadArr] || []
                        ).map((d) => ({
                            value: d._id,
                            label: d.fullName,
                        }))}
                    />
                    <div className="d-flex w-100 justify-content-end mv-position-fixed bottom-0 end-0 p-3 pe-md-0 pb-md-0">
                        <button className="btn btn-primary mv-w-100 bg-base-blue box-shadow-none py-3 py-md-2 mv-pfs-14"

                            onClick={assignLead}
                            style={{
                                borderRadius: '8px',
                                background: assignId ? '#0084EF' : '#DDD'
                            }}
                        >
                            Update
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UnAssignedSidebar