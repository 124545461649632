import React, { useState } from "react";
import bgmain from "../../Assets/bg-main.svg";
import { useNavigate } from "react-router-dom";
import { TextField } from "@mui/material";
import { useSelector } from "react-redux";
import styles from "../Signup/signup.module.css";
import { postReq } from "../../piservices/apis";
import { apiEndpoints } from "../../piservices/enums";
import { PiEyeLight, PiEyeClosedLight } from "react-icons/pi";
import LoginHeader from "../loginHeader/LoginHeader";
import { multipleValidationFunction, toasterControl } from "../../piservices/commonService";
import { apiLinks, signUpValidationObject } from "../../piservices/constants";

const CreatePassword = () => {
  const navigateTo = useNavigate();
  const { otpEmail, userType, companyId } = useSelector((state) => state.persist);
  const [inputPassword, setinputPassword] = useState({
    password: "",
    confirmPassword: "",
  });
  const [confirmErr, setConfirmErr] = useState("");

  const [passedChecks, setPassedChecks] = useState([]);

  const [inputType, setInputType] = useState("password");
  const [confirmInputType, setConfirmInputType] = useState("password");

  const handlePassword = (event) => {
    const { name, value } = event.target;

    if (name === "password") {
      let valArr = multipleValidationFunction(signUpValidationObject[name], value);
      setPassedChecks(valArr);
    }

    if (name === "confirmPassword") {
      if (inputPassword.password === value) {
        setConfirmErr("");
      } else {
        setConfirmErr("Passwords do not match");
      }
    }

    setinputPassword((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  // create password
  const createPass = async () => {
    const res = await postReq(`${apiLinks.crm}${apiEndpoints.createPassword}`, {
      email: otpEmail,
      password: inputPassword.confirmPassword,
    });
    if (res && !res.error) {
      console.log(res);
      toasterControl(true, "Password created successfully.", "success");
      navigateTo("/");
    } else {
      console.log(res.error.response.data.message);
      toasterControl(true, res.error.response.data.message, "error");
    }
  };

  // toggle password
  const toggleConfirmIcon = () => {
    if (confirmInputType === "password") {
      setConfirmInputType("text");
    } else {
      setConfirmInputType("password");
    }
  };

  const togglePasswordIcon = () => {
    if (inputType === "password") {
      setInputType("text");
    } else {
      setInputType("password");
    }
  };

  return (
    <>
      <LoginHeader />
      <div
        style={{
          backgroundImage: `url(${bgmain})`,
        }}
        className={styles.signInContainer}
      >
        <div className={styles.boxContainer}>
          <div className={styles.welcomeHead}>Create Password</div>
          <div className={styles.allTextfield}>
            <div style={{ borderRadius: "12px", width: "100%", display: "flex", flexDirection: "column" }}>
              <TextField
                label="Email"
                variant="outlined"
                className="saas-form mb-3"
                type="email"
                name="email"
                value={otpEmail}
                onChange={handlePassword}
                style={{ borderRadius: "12px", width: "100%" }}
                InputProps={{ style: { borderRadius: "12px" } }}
                disabled
              />
              <div className={styles.passwordInputContainer}>
                <TextField
                  error={passedChecks.length > 0}
                  label="Enter New Password"
                  variant="outlined"
                  className="saas-form mb-3"
                  type={inputType}
                  name="password"
                  value={inputPassword.password}
                  onChange={handlePassword}
                  style={{ borderRadius: "12px", width: "100%" }}
                  InputProps={{ style: { borderRadius: "12px" } }}
                />
                <div className={styles.showPasswordIcon} onClick={togglePasswordIcon}>
                  {inputType === "password" ? <PiEyeLight fontSize={22} color="#4d4d4d" /> : <PiEyeClosedLight fontSize={22} color="#4d4d4d" />}
                </div>
              </div>

              {inputPassword.password.length > 0 && (
                <div className="mb-3">
                  <div className={styles.errorHead}>Your password must contain</div>
                  <div className={passedChecks.includes("minlen") ? styles.success : styles.errorSubHead}>
                    <li>At least 8 characters.</li>
                  </div>
                  <div className={passedChecks.includes("nospecial") ? styles.success : styles.errorSubHead}>
                    <li>At least one special character (@).</li>
                  </div>
                  <div className={passedChecks.includes("noupper") ? styles.success : styles.errorSubHead}>
                    <li>At least one capital letter (P)</li>
                  </div>
                  <div className={passedChecks.includes("maxlen") ? styles.success : styles.errorSubHead}>
                    <li>Maximum characters should be 15</li>
                  </div>
                </div>
              )}

              <div className={styles.passwordInputContainer}>
                <TextField
                  error={confirmErr}
                  label="Confirm Password"
                  variant="outlined"
                  className="saas-form"
                  type={confirmInputType}
                  name="confirmPassword"
                  value={inputPassword.confirmPassword}
                  onChange={handlePassword}
                  style={{ borderRadius: "12px", width: "100%" }}
                  InputProps={{ style: { borderRadius: "12px" } }}
                />
                <div className={styles.showPasswordIcon} onClick={toggleConfirmIcon}>
                  {confirmInputType === "password" ? <PiEyeLight fontSize={22} color="#4d4d4d" /> : <PiEyeClosedLight fontSize={22} color="#4d4d4d" />}
                </div>
              </div>
              {confirmErr && <div className={styles.error}>{confirmErr}</div>}
            </div>

            <div style={{ width: "100%" }}>
              <button
                className={styles.createBtn}
                onClick={() => {
                  inputPassword.password === inputPassword.confirmPassword && createPass();
                }}
              >
                Create
              </button>
            </div>

            <div className={styles.footerPara}>
              On signing you agree to our <u> Terms of Use</u> and <u>Privacy Policy.</u> <br />
              Already having account?
              <span style={{ color: "#0084EF", cursor: "pointer" }} onClick={() => navigateTo("/")}>
                Sign in
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreatePassword;
