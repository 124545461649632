import React, { useContext, useEffect, useRef, useState } from "react";
import desquoContext from "../../../picontext/DashBoard/desquoContext";
import { getReq, postReq, putReq } from "../../../piservices/apis";
import { formateDate, isMd } from "../../../piservices/compCommon";
import expf, { apiLinks } from "../../../piservices/constants";
import attachedFile from "../../../Assets/attach_file_add.svg";
import parse from "html-react-parser";
import "./index.css";
import { useParams } from "react-router-dom";
import { RxCross2 } from "react-icons/rx";
import uuid from "react-uuid";
import Select from 'react-select';
const LmsMail = () => {
  const [mails, setMails] = useState([]);
  // const [fileName, setFileName] = useState([]);
  // const [fileLinks, setFileLinks] = useState([]);
  const [mailFiles, setMailFiles] = useState([]);
  const [emailError, setEmailError] = useState(false);
  const context = useContext(desquoContext);
  const [tempmail, setTempmail] = useState('');
  const emailTypes = {
    followUp: 1,
    not_interested: 5,
    no_response: 4,
    meeting_schedule: 6
  }
  let commonCss = {
    color: "#454545",
    fontSize: "0.875rem",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "normal",
    whiteSpace: "nowrap",
    marginLeft: "1rem",
    cursor: "pointer",
  };
  const {
    activeLead,
    leadstatus,
    setLeadstatus,
    mailStatus,
    setMailStatus,
    getActive,
    activeTem,
    mailForm,
    setMailForm,
    isnewlead,
    setMyLeadStatus,
    dateTime,
    myLeadStatus,
    showAlert,
    leads,
    setLeads,
  } = context;


  let isLead = window.location.href?.includes("lead-details");
  const uploadFileRef = useRef(null);

  const getActiveTemplate =
    getActive &&
    leadstatus[getActive]?.templates &&
    Object.keys(leadstatus[getActive]?.templates)?.filter(
      (f) => leadstatus[getActive]?.templates[f]?.active
    )?.length > 0 &&
    Object.keys(leadstatus[getActive]?.templates)?.filter(
      (f) => leadstatus[getActive]?.templates[f]?.active
    )[0];

  const setTemplate = async (e=0) => {
    try {
      getActiveTemplate &&
        (await setLeadstatus({
          ...leadstatus,
          [getActive]: {
            ...leadstatus[getActive],
            templates: {
              ...leadstatus[getActive]?.templates,
              [getActiveTemplate]: {
                ...leadstatus[getActive]?.templates[getActiveTemplate],
                active: false,
              },
              [e]: {
                ...leadstatus[getActive]?.templates[e],
                active: true,
              },
            },
          },
        }));
    } catch (error) {
      console.log(error);
    }
  };

  function formatTemplateData(data) {
    const tempData = { ...data }
    for (const key in data) {
      tempData[key] = { ...data[key], active: false, body: { newText2: data[key]?.text, subject: data[key]?.subject } }
    }

    tempData[0] = { ...tempData[0], active: true }
    return tempData;
  }

  const init = async () => {
    if (!activeLead?.name) {
      return;
    }
    let mailT = "";

    if (leadstatus && leadstatus[getActive].templates[activeTem]?.body) {
      // alert("adf " + getActive )
      setMailForm(leadstatus[getActive].templates[activeTem]?.body);
      document.querySelector(`#descedit`).innerHTML =
        leadstatus[getActive]?.templates[activeTem]?.body?.newText2 || "";
      return;
    }
    if (
      leadstatus &&
      Object.keys(leadstatus[getActive]?.templates)?.length > 1
    ) {
      mailT = getActive + "_Template" + (parseInt(activeTem) + 1);
    } else {
      mailT = getActive;
    }

    if (mailT === "interested") {
      mailT = "meeting_schedule";
    }
    // const res = await getReq(
    //   expf.dev +
    //   `/user/email-template?templateName=${mailT || "no_response"
    //   }&homeownerName=${activeLead?.name}&date=${new Date()}&time=10:27`
    // );

    const res = await getReq(
      expf.dev +
      `/api/get-all-emailTemplate?type=${emailTypes[getActive] ? emailTypes[getActive] : 1}&userId=${localStorage.getItem("userId")}`
    );
    if (res && !res.error) {
      let x = leadstatus;
      x[getActive].templates = formatTemplateData(res?.data?.data);
      // if (Object.keys(leadstatus[getActive]?.templates)?.length > 1) {
      //   x[getActive].templates[activeTem].body = res?.data?.data;
      // } else {
      //   x[getActive].templates[0].body = res?.data?.data;
      // }
      setLeadstatus(x);
      setMailForm(x[getActive].templates[activeTem]?.body);
      document.querySelector(`#descedit`).innerHTML =
        x[getActive].templates[activeTem]?.body?.newText2 || "";
    }
  };

  useEffect(() => {
    setMails([activeLead?.email]);
    document.getElementById('chipContainerId').scrollLeft = document.getElementById('chipContainerId').scrollWidth
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leadstatus]);

  useEffect(() => {
    // setTemplate(leadstatus[getActive]?.templates[0])
    setMails([activeLead?.email]);
    init();
  }, [])

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getActive]);

  const handleSendEmail = async () => {
    try {
      const res0 = await postReq(expf?.dev + "/user/lead-email", {
        to: mails,
        // "replyTo": "testing4200@gmmail.com",
        subject: mailForm?.subject,
        emailBody: mailForm?.newText2,
        attachments: mailFiles
      });

      if (res0) {
        showAlert(
          "You have successfully sent mail <br><br> Want to update the call status? ",
          "Yes",
          true,
          () => {
            handleSendCallEmail()
          },
          () => { showAlert("Mail Sent successfully ", "Okay"); }
        );
        setMails([activeLead?.email]);
        setMailFiles([])
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSendCallEmail = async () => {
    // await handleSendEmail();

    const res = await postReq(expf?.dev + "/user/newleadActions", {
      leadId: activeLead?._id,
      leadStatus: leadstatus[getActive]?.value,
      leadUpdates: [
        {
          isEmailSend: true,
          callLeadStatus: leadstatus[getActive]?.value,
          text: dateTime[getActive]?.text,
          updateDate:
            dateTime[getActive]?.updateDate || formateDate(new Date()),
          updateTime: dateTime[getActive]?.updateTime,
        },
      ],
    });

    if (res && !res.error) {
      let x = leads;
      x.data[activeLead?.idx] = res?.data?.data;
      await setLeads({ ...x });
      showAlert("Mail Sent successfully and Status updated  ", "Okay");
    }
  };
  function removeMail(index) {
    const filteredMails = mails?.filter((item, idx) => idx !== index);
    setMails(filteredMails);
  }
  function removeFile(index) {
    const filteredFiles = mailFiles?.filter((item, idx) => idx !== index);
    setMailFiles([...filteredFiles])
  }
  function cancelClick() {
    setMails([activeLead?.email]);
    setMailFiles([])
  }

  function setMailData(events) {
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,100}$/i;
    const form4Example1 = document.getElementById('form4Example1')
    if (events.key === "Enter") {
      if (emailPattern.test(events.target.value)) {
        setMails([...mails, events.target.value]);
        setTempmail('')
        setEmailError(false);
        events.target.value = '';
      } else {
        if(tempmail.length > 0) {
          setEmailError(true);
        }else{
          setEmailError(false);
        }
        
        form4Example1.setCustomValidity("I expect an e-mail, darling!");
        // setLeadEmail(inputValue);
        // events.target.value = ;
      }
    }

  }
  useEffect(() => {
    document.getElementById('chipContainerId').scrollLeft = document.getElementById('chipContainerId').scrollWidth
  }, [mails])

  const uploadFile = async (event) => {
    const { files } = event.target;
    for (let i = 0; i < files.length; i++) {
      let filesToUpload = new FormData();
      filesToUpload.append("files", event.target.files[i]);
      const res = await putReq(`${apiLinks.s3api}/api/upload`, filesToUpload);
      if (res && !res.error) {
        setMailFiles([...mailFiles, {
          filename: files[i].name,
          path: res.data.locations[0]
        }])

      } else {
        console.log(res.error);
      }
    }
  };

  return (
    <div>
      <input
        className="d-none"
        ref={uploadFileRef}
        onChange={uploadFile}
        // multiple
        type="file"
        name=""
        id=""
      />
      <div>
        <div
          className="modal fade droppopmodal"
          // style={{zIndex:'100000000'}}
          // data-mdb-backdrop="true"
          data-mdb-keyboard="false"
          id="leadmail"
          data-mdb-backdrop="true"
          tabIndex={-1}
          aria-labelledby="leadmailLabel"
          aria-hidden="true"
        // data-mdb-dismiss="modal"
        // style={window.location.href.includes("lead") ? {} : { zIndex: "2" }}
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content ">
              <div className="modal-body " style={{ height: '92vh' }}>
                {isLead && <h5 className="px-4">Select a template</h5>}
                <div className="d-flex w-100 justify-content-md-center p-2   overflow-auto scb-none">
                  {leadstatus &&
                    Object.keys(leadstatus)?.map((e, i) => {
                      return (
                        !leadstatus[e].nomail &&
                        (isnewlead
                          ? true
                          : !mailStatus?.of
                            ? true
                            : !leadstatus[e].isNewOnly) && (
                          <button
                            key={i}
                            type="button"
                            className={`btn ${leadstatus[e].active
                              ? "btn-secondary  "
                              : "btn-outline-light border cl-grey1 "
                              } px-2 fw-normal me-2 mt-0 ws-nowrap`}
                            data-mdb-ripple-color="dark"
                            onClick={async () => {
                              try {
                                getActive
                                  ? await setLeadstatus({
                                    ...leadstatus,
                                    [getActive]: {
                                      ...leadstatus[getActive],
                                      active: false,
                                      clicked: false
                                    },
                                    [e]: { ...leadstatus[e], active: true, clicked: false },
                                  })
                                  : await setLeadstatus({
                                    ...leadstatus,
                                    [e]: { ...leadstatus[e], active: true, clicked: false },
                                  });
                                isnewlead && setMyLeadStatus(e);
                              } catch (error) {
                                console.log(error);
                              }
                            }}
                          >
                            {leadstatus[e]?.label}
                          </button>
                        )
                      );
                    })}
                </div>
                <p className="px-4 mb-0">Email Template</p>
                <div className="px-4 mt-1 mb-1">
                  {getActive &&
                    leadstatus[getActive]?.templates &&
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      // defaultValue={colourOptions[0]}
                      // isDisabled={isDisabled}
                      // isLoading={isLoading}
                      isClearable={true}
                      // defaultValue={{value:0, label:leadstatus[getActive]?.templates[0].name}}
                      // isRtl={isRtl}
                      isSearchable={true}
                      onChange={(e) => setTemplate(e?.value)}
                      name="assignTo"
                      options={(
                        [...Object.keys(leadstatus[getActive]?.templates)] || []
                      ).map((d) => ({
                        value: d,
                        label: leadstatus[getActive]?.templates[d].name,
                      }))}
                    />
                  }
                </div>
                <div className="mt-2" style={{ height: "60vh" }}>
                  <h6 className="px-4">Send a email to {activeLead?.name}</h6>
                  <hr />
                  <div
                    className="px-4 pt-2 lmsmail"
                    style={{ height: "85%", overflowY: "scroll" }}
                  >
                    <div>
                      <div
                        className="form-outline d-flex align-items-center p-1 inpactive"
                        style={{
                          position: "relative",
                          borderRadius: "5px",
                          border: "1px solid black",
                        }}
                      >
                        <div className="labelTop" style={{ transform: 'translate(10px, -23px)' }}>To </div>
                        <ul className="chipContainerEmail d-flex" id='chipContainerId' style={{ minWidth: mails?.length == 1 ? 'fit-content' : '' }}>
                          {mails?.map((mail, index) => {
                            return (
                              <li className="chipsEmail">
                                {mail}
                                <RxCross2
                                  size={16}
                                  style={{ cursor: "pointer" }}
                                  color="#74737F"
                                  onClick={() => removeMail(index)}
                                />
                              </li>
                            );
                          })}
                        </ul>
                        <input
                          type="text"
                          id="form4Example1"
                          placeholder="Enter mail"
                          autofocus
                          className="form-control br-0"
                          // value={activeLead?.email || ""}
                          onChange={(e) => setTempmail(e.target.value)}
                          onKeyUp={setMailData}
                          style={{ height: 'inherit', borderBottom:'1px solid grey' }}
                        // disabled={true}
                        />
                      </div>
                      <p style={{ display: 'flex', justifyContent: 'end', color: 'red', fontSize: '12px', paddingRight: '2.5rem', transform: 'translateY(-20px)' }}>{emailError && 'Enter valid email'}</p>
                      <div
                        className="form-outline mb-4 p-1"
                        style={{
                          position: "relative",
                          borderRadius: "5px",
                          border: "1px solid black",
                        }}
                      >
                        <div className="labelTop">Subject : </div>

                        {activeTem && (
                          <input
                            type="email"
                            id="form4Example2"
                            className="form-control br-0"
                            value={mailForm?.subject || ""}
                            onChange={(e) => {
                              setMailForm({
                                ...mailForm,
                                subject: e.target.value,
                              });
                            }}
                          />
                        )}
                      </div>
                      <div
                        className="form-outline mb-4 p-1"
                        style={{
                          position: "relative",
                          borderRadius: "5px",
                          border: "1px solid black",
                        }}
                      >
                        <div className="labelTop" >Body : </div>

                        <div
                          className="desc form-control br-0 overflow-auto "
                          style={{
                            height: window.screen.width < 1400 ? "150px" : "200px",
                          }}
                          id={`desc`}
                          onClick={() => {
                            document.querySelector(`#descedit`).innerHTML =
                              document.querySelector(`#desc`)?.innerHTML;
                            document
                              .querySelector(`#descedit`)
                              .classList.remove("d-none");
                            document
                              .querySelector(`#desc`)
                              .classList.add("d-none");
                            document.querySelector(`#descedit`).focus();
                          }}
                        >
                          {parse(mailForm?.newText2 || "")}
                        </div>

                        <div
                          className={`descedit  d-none  form-control overflow-auto  br-0`}
                          style={{
                            height: window.screen.width < 1400 ? "150px" : "200px",
                          }}
                          id={`descedit`}
                          contentEditable="true"
                          suppressContentEditableWarning="true"
                          onInput={(e) => {
                            setMailForm({
                              ...mailForm,
                              newText2: e.target.innerHTML,
                            });
                          }}
                        ></div>
                      </div>

                      {/* Checkbox */}
                    </div>
                  </div><div style={{marginTop:'0.2rem'}}>
                  <div className="d-flex align-items-center" style={{ marginLeft: '1.5rem', height: '1.5rem' }}>
                    <div className="d-flex" >
                      <img
                        src={attachedFile}
                        alt=""
                        style={{ borderWidth: 0 }}
                        onClick={() => uploadFileRef.current.click()}
                      />
                      <span
                        style={commonCss}
                        onClick={() => uploadFileRef.current.click()}
                      >
                        Attach File
                      </span>
                    </div>
                    <div className="chipContainer" style={{ marginLeft: '1.5rem' }}>
                      {mailFiles?.map((file, index) => {
                        return (
                          <div className="chips">
                            <span style={{ whiteSpace: "nowrap" }}>{file?.filename}</span>
                            <span>
                              <RxCross2
                                size={16}
                                style={{ cursor: "pointer" }}
                                color="#74737F"
                                onClick={() => removeFile(index)}
                              />
                            </span>
                          </div>
                        );
                      })}
                    </div>
                  </div>

                  {/* Submit button */}
                  <div className="d-flex justify-content-end" style={{ paddingTop: '1rem', paddingBottom: '1rem' }}>
                    <div className="mv-w-50 px-2">
                      <button
                        type="button"
                        className="btn btn-light bg-grey1 mv-w-100  px-5  "
                        data-mdb-toggle="modal"
                        data-mdb-target={`${isMd && "#leadmail"}`}
                        onClick={() => cancelClick()}
                      >
                        Cancel
                      </button>
                    </div>

                    <div className="mv-w-50 px-2">
                      {mailStatus?.of && !leadstatus[getActive]?.addEmail ? (
                        <button
                          type="button"
                          className="btn btn-primary mv-w-100 bg-base-blue box-shadow-none px-5  "
                          onClick={() => {
                            setMailStatus({ send: true, of: getActive });
                          }}
                          data-mdb-target={`${isMd ? "#staticBackdrop" : ""}`}
                          data-mdb-dismiss="modal"
                          data-mdb-toggle="modal"
                        >
                          Save
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="btn btn-primary mv-w-100 box-shadow-none px-5 "
                          onClick={() => {
                            // setMailStatus({ send: true, "of": getActive })
                            // if (leadstatus[getActive]?.addEmail) {
                            //   handleSendCallEmail();
                            // } else {
                            handleSendEmail();
                            // }
                          }}
                          data-mdb-target={`${isMd ? "#staticBackdrop" : ""}`}
                          data-mdb-dismiss="modal"
                          data-mdb-toggle="modal"
                          disabled={tempmail.length > 0 || emailError}
                        >
                          Send Mail
                        </button>
                      )}
                    </div>
                  </div></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LmsMail;
