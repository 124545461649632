import React, { useState } from "react";
import LeadUpdate from "../LeadUpdate/LeadUpdate";
import { useParams } from "react-router-dom";

function LeadInfoSidePanel({rerender, setRerender, setUpdatePanel, updatePanel}) {
  let { tab } = useParams();
  const [showScroll, setShowScroll] = useState(false);
  // const [widthOfPanel, setWidthOfPanel] = useState(
  //   window.screen.width > 1341 ? "22.5rem" : "18.5rem"
  // );
  return (
    <>
      <div
        style={{
          height: `${document.getElementById("td_table")?.offsetHeight}px`,
        }}
      >
        <div
          className="d-flex"
          id="td"
          style={{
            overflowX: "hidden",
            width: "100%",
            position: "sticky",
            top: "0px",
            // right: "0px",
            // top: `${a}px`,
          }}
        >
          <div style={{ width: "fit-content", display: "flex" }}></div>
          <div
            // animate={{ x: `${panelSlide}rem` }}
            className={`me-2 br-5`}
            style={{
              height: "78vh",
              width:window.location.href.includes('lead-details')?'100%':'57%',
              overflowX: "hidden",
              border: '1px solid #EDEDED',
              paddingBottom:'5%'
            }}
            onMouseEnter={() => setShowScroll(true)}
            onMouseLeave={() => {
              setTimeout(() => {
                setShowScroll(false);
              }, 2500);
            }}
          >
            <LeadUpdate  setUpdatePanel={setUpdatePanel} updatePanel = {updatePanel} rerender = {rerender} setRerender={setRerender}  tab={tab} />
          </div>
        </div>
      </div>
    </>
  );
}

export default LeadInfoSidePanel;
