import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { getReq } from '../../../piservices/apis';
import { apiLinks } from '../../../piservices/constants';
import { formateDate } from '../../../piservices/compCommon';
import styles from "./InteractionAndLogs.module.css";
import { BsEye } from 'react-icons/bs'
import { useParams } from 'react-router-dom';
import { ThreeDots } from 'react-loader-spinner'

function LogComponent({ logDate, logs }) {

  return (
    <>
      <div className={`${styles.container}`}>
        <div className={`${styles.leftContainer}`}>{logDate && logDate}</div>
        <div className={`${styles.rightContainer}`}>
          {logs[logDate].map((it, index) => <><div className={styles.subRightContainer} style={{ display: 'flex', justifyContent: 'space-between', borderLeft: '0.75px solid #999', paddingLeft: '20px' }}>
            <div>{it && (it.logName || it.logText)}</div>
            {/* <div>{it.assignTo && `Assign to :${it.assignTo} `}</div> */}
            {/* <div>Status: {it.status}</div> */}
            <div style={{ color: '#0084EF', cursor: 'pointer' }}><BsEye /> <span>{`View`}</span></div>
          </div><hr /></>)}
        </div>
      </div>
    </>
  )
}

function Logs() {
  const { id } = useParams()
  const selectedLeadData = useSelector(
    (state) => state.selectedLeadSlice.selectedLeadData
  );
  const [logs, setlogs] = useState([])
  const [loading, setLoading] = useState(false)

  const getlogData = async () => {
    setLoading(true);
    const res = await getReq(
      `${apiLinks.pmt}/api/projects/get-all-logs?projectId=${selectedLeadData?.projectId}`
    );
    if (res && !res.error) {
      setLoading(false);
      const groupByCreatedDate = res?.data?.reduce(
        (group, product) => {
          const { createdAt } = product;
          const formatedDate = formateDate(createdAt)
          group[formatedDate] = group[formatedDate] ?? [];
          group[formatedDate].push(product);
          return group;
        },
        {}
      );
      setlogs(groupByCreatedDate);
    }
    else {
      console.log(res.error);
      setLoading(false);
    }
  };
  useEffect(() => {
    getlogData()
  }, [selectedLeadData])
  return (
    <>
      {loading ? <div
        className=""
        style={{
          position: 'relative',
          marginTop: '17vh',
          marginLeft: '40vw',
          // transform: 'translate(-50%, -50%)'
        }}
      >
        <ThreeDots
          height="80"
          width="80"
          radius="9"
          color="#0084EF"
          ariaLabel="three-dots-loading"
          wrapperStyle={{}}
          wrapperClassName=""
          visible={true}
        />
      </div> :
        <div className={`${styles.mainContainer}`}>
          {
            logs && Object.keys(logs).length > 0 ? Object.keys(logs).map((logDate, index) => <LogComponent logs={logs} key={index} logDate={logDate} />) : <h4>There is no logs</h4>
          }

        </div>}
    </>
  );
}

export default Logs