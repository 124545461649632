import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    selectedLeadData:{},
    updatePanel: false,
}
export const selectedLeadSlice = createSlice({
    name: 'selectedLead',
    initialState,
    reducers:{
        selectedLeadData:(state, action)=>{
            state.selectedLeadData = action.payload;
        },
        updateClosureMonth:(state, action)=>{
            state.selectedLeadData = action.payload
        },
        updatePanelOpen:(state, action)=>{
            state.updatePanel = action.payload
        }
    }
})

export const { selectedLeadData,updatePanelOpen, updateClosureMonth} = selectedLeadSlice.actions;
export default selectedLeadSlice.reducer;
