import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  panelSlide: 0,
  createProject: false,
  createTeam: false,
  currentLeadStatus: "",
  newProject: "",
  isFilterOpen:false,
  nextPanel: {},
  sidebarToggle: true,
  selectedArea: [],
  selectedBudget: [],
  searchTerms:'',
  selectedScope: [],
  selectedExpClosure: [],
  isChecked: false,
  selectedAssignedTo: [],
  panelUpdated: false,
  leadSources:[],
  sourceNames:[],
  userData:{},
  teamLeadArr:[],
  workType:[],
  profileData:'',
  selectedTab:'Leads Management',
  leadsCount:{}
};

export const lmsSlice = createSlice({
  name: "lmsActions",
  initialState,
  reducers: {
    setLeadsCount:(state, action) => {
      state.leadsCount = action.payload
    },
    setProfileData : (state, action) => {
      state.profileData = action.payload
    },
    setSelectedTabs:(state, action) => {
      state.selectedTab = action.payload
    },
    setWorkType:(state,action) => {
      state.workType = action.payload
    },
    setLeadSources: (state, action) =>{
      state.leadSources = action.payload
    },
    setSourceNames: (state, action) =>{
      state.sourceNames = action.payload
    },
    setUserData: (state,action) => {
    state.userData = action.payload
    },
    setTeamLeadArr: (state, action) =>{
      state.teamLeadArr = action.payload
    },
    slideOut: (state, action) => {
      state.panelSlide = state.panelSlide + action.payload;
    },
    slideIn: (state, action) => {
      state.panelSlide = state.panelSlide - action.payload;
    },
    createProjectModal: (state, action) => {
      state.createProject = action.payload;
    },
    createTeam: (state, action) => {
      state.createTeam = action.payload;
    },
    isFilterOpen: (state, action) => {
      state.isFilterOpen = action.payload
    },
    setSelectedBudget: (state, action) => {
      state.selectedBudget = action.payload;
    },
    currentLead: (state, action) => {
      state.currentLeadStatus = action.payload;
    },
    setSelectedArea: (state, action) => {
      state.selectedArea = action.payload;
    },
    setIsChecked: (state, action) => {
      state.isChecked = action.payload;
    },
    createTeam: (state, action) => {
      state.createTeam = action.payload;
    },
    newProjectData: (state, action) => {
      state.newProject = action.payload;
    },

    selectNextSlide: (state, action) => {
      state.nextPanel = { ...action.payload.leadData };
    },
    setSidebarToggle: (state, action) => {
      state.sidebarToggle = action.payload;
    },
    setSearchTerms: (state, action) =>{
      state.searchTerms = action.payload;
    },
    setPanelUpdate: (state, action) =>{
      state.panelUpdated = action.payload;
    }
  },
});

// Action creators are generated for each case reducer function
export const {
  setLeadsCount,
  slideOut,
  setProfileData,
  slideIn,
  setTeamLeadArr,setUserData,setSourceNames,setLeadSources,
  selectNextSlide,
  createProjectModal,
  setSelectedArea,
  setSelectedBudget,
  setSelectedScope,
  setSelectedExpClosure,
  setWorkType,
  setSelectedAssignedTo,
  setIsChecked,
  createTeam,
  currentLead,
  newProjectData,
  isFilterOpen,
  setSearchTerms,
  setSelectedTabs,
  setSidebarToggle,
  setPanelUpdate
} = lmsSlice.actions;

export default lmsSlice.reducer;
