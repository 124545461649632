import { TextField } from "@mui/material";
import React, { useState } from "react";
import bgmain from "../../Assets/bg-main.svg";
import CountUp from "react-countup";
import LoginHeader from "../loginHeader/LoginHeader";
import { useSelector } from "react-redux";
import { postReq } from "../../piservices/apis";
import { apiEndpoints } from "../../piservices/enums";
import { useNavigate } from "react-router-dom";
import { apiLinks } from "../../piservices/constants";
import { toasterControl } from "../../piservices/commonService";

const VerifyOtpForgot = () => {
  const navigateTo = useNavigate();
  const { otpEmail } = useSelector((state) => state.persist);
  const [showResend, setShowResend] = useState(false);
  const [otpText, setOtpText] = useState("");

  const handleInput = (event) => {
    if (event.target.value.length < 5) {
      setOtpText(event.target.value);
    }
  };

  const verifyOtp = async () => {
    const res = await postReq(`${apiLinks.emailApi}${apiEndpoints.verifyOtp}`, {
      email: otpEmail,
      otp: otpText,
    });
    if (res && !res.error) {
      if (res.data.message && res.data.message === "Failed") {
        toasterControl("toasterState", true, "error", "OTP is invalid");
      } else {
        navigateTo("/create-password");
      }
    } else {
      console.error(res.error);
    }
  };

  const getResendOtp = async () => {
    const res = await postReq(`${apiLinks.emailApi}${apiEndpoints.sendOtp}`, {
      email: otpEmail,
    });
    if (res && !res.error) {
      setShowResend(false);
    } else {
      console.error(res.error);
    }
  };
  return (
    <>
      <LoginHeader />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "calc(100vh - 4rem)",
          wifth: "100%",
          backgroundImage: `url(${bgmain})`,
          backgroundRepeat: " no-repeat",
          backgroundSize: "100%",
          backgroundPositionY: "center",
        }}
      >
        <div style={{ width: "40%", border: "1px solid #E0E3E6", borderRadius: "12px", backgroundColor: "#ffffff" }}>
          <div style={{ padding: "24px", borderBottom: "1px solid #E0E3E6", color: "#40454A", fontFamily: "Inter", fontSize: "28px", fontStyle: "normal", fontWeight: 700, lineHeight: "28px" }}>
            Verification
          </div>
          <div style={{ padding: "24px 24px 28px 24px", display: "flex", flexDirection: "column", alignItems: "center", gap: "1.5rem" }}>
            <div style={{ color: "#999", fontFamily: "Inter", fontSize: "16px", fontStyle: "normal", fontWeight: 500, lineHeight: "28px" }}>You will get an OTP via email</div>
            <div style={{ borderRadius: "12px", width: "100%" }}>
              <TextField fullWidth id="outlined-basic" label="OTP" variant="outlined" InputProps={{ style: { borderRadius: "12px" } }} value={otpText} onChange={handleInput} />
            </div>

            <div style={{ width: "100%" }}>
              <button
                style={{
                  borderRadius: "8px",
                  border: "1px solid #0084EF",
                  background: "#0084EF",
                  width: "100%",
                  padding: "10px 14px",
                  color: "#ffffff",
                  fontFamily: "Inter",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 700,
                  lineHeight: "20px",
                }}
                onClick={() => verifyOtp()}
              >
                Verify
              </button>
            </div>

            <div cl style={{ color: "#999", fontFamily: "Inter", fontSize: "14px", fontStyle: "normal", fontWeight: 500, lineHeight: "28px", display: "flex" }}>
              <div style={{ marginRight: "0.5rem" }}>Didn’t receive the verification OTP?</div>
              {!showResend && <span>Resend in</span>}
              {showResend ? (
                <div style={{ cursor: "pointer", color: "#0084EF" }} onClick={() => getResendOtp()}>
                  Resend again
                </div>
              ) : (
                <>
                  <CountUp start={30} end={0} duration={30} useEasing={false} onEnd={() => setShowResend(true)} style={{ marginLeft: "0.3rem" }} />
                  <span style={{ marginLeft: "0.2rem" }}>sec</span>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VerifyOtpForgot;
