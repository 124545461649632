export function whatsAppApi(number) {
  return `https://api.whatsapp.com/send?phone=91${number}&text=Hi%2C+Greetings`;
}
const expf = {
  // api: process.env.REACT_APP_API_URL,
  // api: "http://localhost:3900/api",
  apiurl: process.env.REACT_APP_URL,
  // proapi: "https://crm.idesign.market",
  // proapi: "http://65.2.27.46:8000",
  proapi: "https://crm.vandrcontracts.in",
  quoapi: "https://quotation-api.idesign.market",
  pmtapi: "https://pmt-api.vandrcontracts.in",
  // dev : "https://pro-api.idesign.market",
  // dev: "https://pro-api.idesign.market",+
  // dev: "httpd://65.2.27.46:8000",
  dev: "https://crm.vandrcontracts.in",
  whatsAppApi,
};

export const apiLinks = {
  notify:"https://notify-api.vandrcontracts.in",
  s3api: "https://s3-service.idesign.market",
  pmt: "https://pmt-api.vandrcontracts.in",
  crm: "https://crm.vandrcontracts.in",
  email:"https://email-api.vandrcontracts.in",
  S3_BUCKET: "https://s3-api.vandrcontracts.in"
};

export const BASE_URL = "https://erp.vandrcontracts.in";

export const base_url = "https://erp.vandrcontracts.in";

export default expf;

export const signUpValidationObject = {
  password: {
    required: true,
    minLength: 8,
    maxLength: 16,
    upperCasePattern: /[A-Z]/,
    specialCharacterPattern: /[!@#$%^&*()_+{}[\]:;<>,.?~|/-]/,
  },
};

export const signInValidationObject = {
  email: {
    label: "Email",
    required: true,
    pattern: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
  },
  password: {
    required: true,
  },
};

export const numberArray = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];

export const emailValidation = {
  email: {
    label: "Email",
    required: true,
    pattern: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
  },
};

export const adminPersonalDataValObj = {
  fname: {
    required: true,
  },
  lName: {
    required: true,
  },
  address: {
    required: true,
  },
  city: {
    required: true,
  },
  zipCode: {
    required: true,
    minLength: 6,
    maxLength: 6,
  },
  birth: {
    required: true,
  },
  number: {
    required: true,
  },
};

export const verifyOtpValObj = {
  otp: {
    required: true,
    minLength: 4,
    maxLength: 4,
  },
};

export const teamOnboardVerifyObj = {
  fName: {
    required: true,
    maxLength: 15,
  },
  Lname: {
    required: true,
    maxLength: 15,
  },
  email: {
    label: "Email",
    required: true,
    pattern: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
  },

  role: {
    required: true,
  },

  designation: {
    required: true,
  },
};

export const getOtpVerifyObj = {
  email: {
    label: "Email",
    required: true,
    pattern: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
  },
};
