import React from "react";
import idesign from "../../Assets/logo.png";
import { useNavigate } from "react-router-dom";
import styles from "../loginHeader/loginHeader.module.css";

const LoginHeader = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className={styles.container}>
      <img src="https://idesign-office.s3.amazonaws.com/WhatsApp%20Image%202023-05-29%20at%2012.38%201%20%281%29.svg"
          style={{ cursor: "pointer" }}
         
          alt=""
          height="36"
        />
      </div>
    </>
  );
};

export default LoginHeader;
