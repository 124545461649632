import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    addLeadModalOpen: false,
    newLeadObj:{}
}
export const newLeadSlice = createSlice({
    name: 'newLead',
    initialState,
    reducers:{
        setAddLeadModalOpen:(state, action)=>{
            state.addLeadModalOpen = action.payload;
        },
        newLeadObj:(state, action)=>{
            state.newLeadObj = action.payload;
        }
    }
})

export const { setAddLeadModalOpen, newLeadObj} = newLeadSlice.actions;
export default newLeadSlice.reducer;
