// import { TextField } from '@mui/material';
import React, { useState } from "react";
import bgmain from "../../Assets/bg-main.svg";
import { useNavigate } from "react-router-dom";
import { TextField } from "@mui/material";
import { postReq } from "../../piservices/apis";
import { apiEndpoints } from "../../piservices/enums";
import { useSelector } from "react-redux";
import CountUp from "react-countup";
import LoginHeader from "../loginHeader/LoginHeader";
import styles from "./verifyUser.module.css";
import { checkIfHasOnlyNumbers, validationFunction } from "../../piservices/commonService";
import { apiLinks, verifyOtpValObj } from "../../piservices/constants";

const VerifyUser = () => {
  const navigateTo = useNavigate();
  const { otpEmail } = useSelector((state) => state.persist);
  const [otp, setOtp] = useState("");
  const [showResend, setShowResend] = useState(false);
  const [error, setError] = useState(false);

  const handleOtp = (event) => {
    const { value } = event.target;
    const valText = validationFunction(verifyOtpValObj["otp"], value);
    if (valText) {
      setError(true);
    } else {
      setError(false);
    }

    if (checkIfHasOnlyNumbers(value)) {
      if (value.length < 5) {
        setOtp(value);
      }
    }
  };

  const verifyOtp = async () => {
    const res = await postReq(`${apiLinks.emailApi}${apiEndpoints.verifyOtp}`, {
      email: otpEmail,
      otp: otp,
    });
    if (res && !res.error) {
      navigateTo("/sign-up");
    } else {
      console.log(res.error);
    }
  };

  const getResendOtp = async () => {
    const res = await postReq(`${apiLinks.emailApi}${apiEndpoints.sendOtp}`, {
      email: otpEmail,
    });
    if (res && !res.error) {
      setShowResend(false);
    } else {
      console.log(res.error);
    }
  };

  return (
    <>
      <LoginHeader />
      <div
        style={{
          backgroundImage: `url(${bgmain})`,
        }}
        className={styles.signInContainer}
      >
        <div style={{ width: "40%", height: "fit-content", border: "1px solid #E0E3E6", borderRadius: "12px", backgroundColor: "#ffffff" }}>
          <div style={{ padding: "24px", borderBottom: "1px solid #E0E3E6", color: "#40454A", fontFamily: "Inter", fontSize: "28px", fontStyle: "normal", fontWeight: 700, lineHeight: "28px" }}>
            Verification
          </div>
          <div style={{ padding: "24px 24px 28px 24px", display: "flex", flexDirection: "column", alignItems: "center", gap: "1.5rem" }}>
            <div style={{ color: "#999", fontFamily: "Inter", fontSize: "16px", fontStyle: "normal", fontWeight: 500, lineHeight: "28px", width: "100%" }}>You will get an OTP via email</div>
            <div style={{ borderRadius: "12px", width: "100%" }}>
              <TextField
                error={error}
                type="text"
                fullWidth
                id="outlined-basic"
                label="OTP"
                variant="outlined"
                value={otp}
                onChange={handleOtp}
                InputProps={{ style: { borderRadius: "12px" } }}
                // inputProps={{
                //   pattern: "[0-9]*", // Allow only numeric input on mobile devices
                //   inputMode: "numeric", // Display numeric keyboard on mobile devices
                // }}
              />
            </div>

            <div style={{ width: "100%" }}>
              <button
                style={{
                  borderRadius: "8px",
                  border: "1px solid #0084EF",
                  background: "#0084EF",
                  width: "100%",
                  padding: "10px 14px",
                  color: "#ffffff",
                  fontFamily: "Inter",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 700,
                  lineHeight: "20px",
                }}
                onClick={otp !== "" ? () => verifyOtp() : null}
              >
                Verify
              </button>
            </div>

            <div cl style={{ color: "#999", fontFamily: "Inter", fontSize: "14px", fontStyle: "normal", fontWeight: 500, lineHeight: "28px", display: "flex" }}>
              <div style={{ marginRight: "0.5rem" }}>Didn’t receive the verification OTP?</div>
              {!showResend && <span>Resend in</span>}
              {showResend ? (
                <div style={{ cursor: "pointer", color: "#0084EF" }} onClick={() => getResendOtp()}>
                  Resend again
                </div>
              ) : (
                <>
                  <CountUp start={30} end={0} duration={30} useEasing={false} onEnd={() => setShowResend(true)} style={{ marginLeft: "0.3rem" }} />
                  <span style={{ marginLeft: "0.2rem" }}>sec</span>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VerifyUser;
