import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  otpEmailsss: "",
  designationArr: [],

  //toaster states
  toasterMessage: "",
  toasterState: false,
  toasterVariant: "success",
};

export const onboardingSlice = createSlice({
  name: "onboardingSlice",
  initialState,
  reducers: {
    saveOtpMailsss: (state, action) => {
      state.otpEmail = action.payload;
    },

    saveDesignationArr: (state, action) => {
      state.designationArr = action.payload;
    },

    toasterController: (state, action) => {
      state[action.payload.toaster] = action.payload.state;
      state.toasterState = action.payload.state;
      state.toasterMessage = action.payload.message;
      state.toasterVariant = action.payload.variant;
    },
  },
});

export const { saveOtpMailsss, saveDesignationArr, toasterController } = onboardingSlice.actions;

export default onboardingSlice.reducer;
