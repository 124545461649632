import React, { useContext } from "react";
import desquoContext from "../../../picontext/DashBoard/desquoContext";
import { isMd } from "../../../piservices/compCommon";
import {useSelector} from 'react-redux'

const Remark = () => {
  const context = useContext(desquoContext);
  const { isnewlead, dateTime, setDateTime, getActive } = context;
  const userData = useSelector((state) => state.lmsSlice.userData);
  return (
    <div className="mt-3">
      {isnewlead ? <p className="fw-bold">Today’s Update</p> : <hr />}
      <textarea
        name=""
        id=""
        disabled={!userData?.rolesAssigned?.crm?.edit}
        rows={isnewlead ? (isMd ? "5" : "2") : "2"}
        placeholder="Minutes of call"
        value={dateTime[getActive]?.text || ""}
        onChange={(e) => {
          setDateTime({
            ...dateTime,
            [getActive]: { ...dateTime[getActive], text: e.target.value },
          });
        }}
        className="br-5 bg-grey1 border w-100 p-2 pfs-14"
      ></textarea>
    </div>
  );
};

export default Remark;
