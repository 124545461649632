import React from "react";
import {
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Autocomplete,
  FormHelperText,
  Select,
  TextField,
} from "@mui/material";
import { Button, Modal } from "react-bootstrap";
import {
  setAddLeadModalOpen,
  newLeadObj,
} from "../../Redux/Slices/newLeadSlice";
import styles from "./AddLeadModalNew.module.css";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import expf, { apiLinks } from "../../piservices/constants";
import { useEffect } from "react";
import { getReq, postReq } from "../../piservices/apis";
import { toast } from "react-toastify";
import MuiPhoneNumber from "material-ui-phone-number";
import { notification } from "antd";

function AddLeadModalNew({ setProgress }) {
  const addLeadModalOpen = useSelector(
    (state) => state.newLeadSlice.addLeadModalOpen
  );
  const dispatch = useDispatch();
  const [leadFirstName, setLeadFirstName] = useState("");
  const [leadLastName, setLeadLastName] = useState("");
  const [phoneNumber, setPhonenumber] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [errorPin, setErrorPin] = useState(false);
  const [leadEmail, setLeadEmail] = useState("");
  const [leadCity, setLeadCity] = useState("");
  const [leadArea, setLeadArea] = useState("");
  const [leadAddress, setLeadAddress] = useState("");
  const [workScope, setWorkScope] = useState("");
  const [leadCityCode, setLeadCityCode] = useState("");
  const [userData, setUserData] = useState({});
  const [leadSources, setLeadSources] = useState([]);
  const [leadWorkTypes, setLeadWorkTypes] = useState([]);
  const [sourceNames, setSourceNames] = useState([]);
  const [leadSource, setLeadSource] = useState([]);
  const [leadWorkType, setLeadWorkType] = useState([]);
  const [sourceName, setSourceName] = useState([]);
  const [selectedLeadSource, setSelectedLeadSource] = useState("");
  const [assignedTo, setAssignedTo] = useState("");
  const [areaUnit, setAreaUnit] = useState('sqft');
  const [selectedSourceName, setSelectedSourceName] = useState("");
  const [firstNameError, setFirstNameError] = useState(false)
  const [lastNameError, setLastNameError] = useState(false)
  const [leadSourceClicked, setLeadSourceClicked] = useState(false)
  const [sourceNameArchitectDetails, setSourceNameArchitectDetails] = useState(
    {}
  );

  const [formData, setFormData] = useState({})
  const [teamLeadArr, setTeamLeadArr] = useState([]);
  const [budget, setBudget] = useState();
  const [error, setError] = useState(false);
  const projectObj = useSelector((state) => state.newLeadObj);
  const tempObj = {
    firstName: "",
    lastName: "",
    email: "",
    address: "",
    city: "",
    pinCode: "",
    phoneNumber: "",
    budget: "",
    requirements: "",
    leadSource: "",
    sourceName: "",
    assignedTo: "",
    area: "",
  };
  const exceptThisSymbols2 = [
    "~",
    "`",
    "!",
    "#",
    "%",
    "$",
    "^",
    "&",
    "*",
    "(",
    " ",
    ")",
    "+",
    "=",
    "[",
    "_",
    "]",
    "'",
    ";",
    "/",
    "{",
    "}",
    "|",
    ,
    "<",
    ">",
    "?",
    ",",
  ];
  const leadFirstNameHandle = (e) => {
    if (e.target.value?.length <= 23 && /^[a-zA-Z\s]*$/.test(e.target.value)) {
      setLeadFirstName(e.target.value);
      setFirstNameError(false)
    }
    else {
      setFirstNameError(true);
    }
  }

  const leadCityHandle = (e) => {
    if (e.target.value?.length <= 23 && /^[a-zA-Z\s]*$/.test(e.target.value)) {
      setLeadCity(e.target.value);
    }
  }

  const leadLastNameHandle = (e) => {
    if (e.target.value?.length <= 15 && (/^[a-zA-Z\s]*$/).test(e.target.value)) {
      setLeadLastName(e.target.value);
      setLastNameError(false)
    } else {
      setLastNameError(true)
    }
  };
  const handlePhoneNumberChange = (value) => {
    let phoneNumber = value?.replaceAll(' ', '')?.replaceAll('-', '')?.replaceAll('+', '')
    console.log('Phone Number', value)
    setPhonenumber(value);
  };
  const handleNewLeadClose = () => {
    setLeadFirstName('')
    setLeadLastName('');
    setPhonenumber('')
    setEmailError(false);
    setLeadEmail('')
    setLeadCity('')
    setLeadArea('')
    setLeadAddress('')
    setWorkScope('')
    setAssignedTo('')
    setErrorPin(false)
    setLeadArea('')
    setLeadCityCode('')
    setBudget('')
    dispatch(setAddLeadModalOpen(false));
  };
  const handlePinCodeChange = (event) => {
    const inputValue = event.target.value.replace(/[^0-9]/g, "");
    if (event.target.value?.length <= 6) {
      setLeadCityCode(inputValue);

      if (
        /^\d+$/.test(inputValue) &&
        inputValue.length >= 6 &&
        inputValue.length <= 6
      ) {
        setLeadCityCode(inputValue);
        setErrorPin(false);
      } else {
        setErrorPin(true);
        setLeadCityCode(inputValue);
      }
    }
  };
  const handleEmailChange = (event) => {
    const inputValue = event.target.value;
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,20}$/i;
    const inputValueIncludeATTHERATE = inputValue.includes('@')
    if (inputValue.length < 64 && emailPattern.test(inputValue)) {
      setLeadEmail(inputValue);
      setEmailError(false);
    } else {
      if (inputValue?.length == 0) {
        setEmailError(false);
      } else {
        setEmailError(true);
      }
      if (inputValue.length < 64) {
        setLeadEmail(inputValue);
      }

    }

  };

  const handleBudget = (event) => {
    const inputValue = event.target.value.replace(/[^0-9]/g, "");
    if (event.target.value?.length <= 10) {
      setBudget(inputValue);
      if (
        /^\d+$/.test(inputValue) &&
        inputValue.length <= 9
      ) {
        setBudget(inputValue);
      }
    }
  };

  function handleLeadArea(event) {
    const inputValue = event.target.value.replace(/[^0-9]/g, "");
    if (event.target.value?.length <= 6) {
      setLeadArea(inputValue);
      if (
        /^\d+$/.test(inputValue) &&
        inputValue.length <= 6
      ) {
        setLeadArea(inputValue);
      }
    }
  }

  const handleSubmit = () => {
    if (
      leadFirstName == "" ||
      leadFirstName == undefined ||
      phoneNumber == "" ||
      phoneNumber == undefined ||
      leadEmail == "" ||
      leadEmail == undefined ||
      leadCity == "" ||
      leadCity == undefined ||
      // leadArea == "" ||
      // leadArea == undefined ||
      leadAddress == "" ||
      leadAddress == undefined ||
      leadCityCode == "" ||
      leadCityCode == undefined ||
      error ||
      errorPin ||
      emailError
    ) {
      alert("Please enter all the details");
    } else {
      handleFormSubmit({
        firstName: leadFirstName,
        lastName: leadLastName ? leadLastName : "",
        email: leadEmail,
        address: leadAddress,
        city: leadCity,
        pinCode: leadCityCode,
        phoneNumber: phoneNumber,
        budget: budget,
        requirements: workScope,
        leadSource: selectedLeadSource,
        sourceName: selectedSourceName,
        assignedTo: assignedTo,
        area: leadArea,
        areaUnit: areaUnit
      });
    }
  };
  const getUserbyId = async (id) => {
    const res = await getReq(`${apiLinks.crm}/user/getUserById?userId=${id}`);
    if (res && !res.error) {
      setUserData(res?.data?.data);
    } else {
      console.log(res.error);
    }
  };

  const showError = (error) => {
    // console.log(error)
    if (error.response && error.response.data.message) {
      toast.error(error.response.data.message);
      // clg
      // alert(error.response.data.message)
      return;
    }
    else {
      window.location.reload();
    }
  };
  const handleFormSubmit = async (data) => {
    // console.log({...data});
    try {
      // await setProgress(30);
      for (const k in data) {
        const e = data[k];
        if (e?.trim() === "") {
          delete data[k];
        }
      }
      // if (data?.assignedTo == "UnAssigned") {
      //   delete data["assignedTo"];
      //   data["assignedTo"] = null;
      // }
      // console.log(data);
      const res = await postReq(expf.proapi + "/user/addEditLead", {
        ...data,
        architectDetails: sourceNameArchitectDetails,
      });
      if (res.error) {
        showError(res.error);
      }

      if (res && !res.error) {
        dispatch(setAddLeadModalOpen(false))
        notification.success({
          message: res?.message || "Lead Created Successfully",
          duration: 2,
        });
        window.location.reload();
      }
      await setProgress(100);
    } catch (error) {
      // console.log(error)
      // dispatch(setAddLeadModalOpen(false))
      console.log('errorrrrr')
      // showError(error);
    }
  };
  const getTeamLeads = async () => {
    const res = await getReq(
      `${expf.proapi
      }/api/enterprise/get-team-leaders?userId=${localStorage.getItem(
        "userId"
      )}&access=1`
    );
    if (res && !res.error) {
      setTeamLeadArr(res.data.data);
    } else {
      console.log(res.error);
    }
  };

  const getLeadSource = async () => {
    const res = await getReq(
      `${expf.proapi}/user/get-lead-source?userId=${localStorage.getItem(
        "userId"
      )}`
    );
    if (res && !res.error) {
      // console.log('getting lead source', res.data.data)
      setLeadSources(res.data.data);
    } else {
      console.log(res.error);
    }
  };
  const getWorkTypes = async () => {
    const res = await getReq(
      `${expf.proapi}/user/get-work-types?userId=${localStorage.getItem(
        "userId"
      )}`
    );
    if (res && !res.error) {
      setLeadWorkTypes(res.data.data);
    } else {
      console.log(res.error);
    }
  };
  const getSourceName = async () => {
    const res = await getReq(
      `${expf.proapi}/user/get-source-name?userId=${localStorage.getItem(
        "userId"
      )}`
    );
    if (res && !res.error) {
      // console.log('getting lead source', res.data.data)
      setSourceNames(res.data.data);
    } else {
      console.log(res.error);
    }
  };
  const handleLeadSource = (e) => {
    const tempLeadSource = leadSources.find(
      (leadSource) => leadSource.id === e.target.value
    );
    setSelectedLeadSource(tempLeadSource.leadSource);
    setLeadSource(e.target.value);
  };
  const handleSourceName = (e) => {
    setSourceName(e.target.value);
    const tempSourceName = sourceNames.find(
      (sourceName) => sourceName._id === e.target.value
    );
    setSelectedSourceName(tempSourceName.sourceName);
    setSourceNameArchitectDetails(tempSourceName?.architectDetails);
  };

  useEffect(() => {
    getLeadSource();
    getSourceName();
    getTeamLeads();
    getWorkTypes();
    getUserbyId(localStorage.getItem("userId"));
  }, []);

  <script type="text/javascript"></script>
  return (
    <>
      <Modal
        show={addLeadModalOpen}
        onHide={() => handleNewLeadClose()}
        backdrop="static"
        keyboard={false}
        centered
      // size="lg"
      // width='1000px'
      >
        <Modal.Header
          closeButton
          style={{
            borderBottom: "none",
            backgroundColor: "#0084ef",
            color: "#ffffff",
          }}
        >
          <Modal.Title>Add New Lead</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ width: "31rem" }}>
          <div className={styles.formName}>
            <TextField
              style={{ width: "14rem" }}
              id="outlined-basic"
              label="First Name*"
              size="small"
              helperText={firstNameError && leadFirstName.length == 0 ? 'Please enter first name' : firstNameError && "Limit exceeded 23 characters"}
              variant="outlined"
              onFocus={() => setFirstNameError(true)}
              isRequired={true}
              error={firstNameError}
              value={leadFirstName}
              InputProps={{
                inputMode: "text",
              }}
              onChange={(e) => {
                leadFirstNameHandle(e);
              }}
            />

            <TextField
              style={{ width: "14rem" }}
              id="outlined-basic"
              label="Last Name"
              variant="outlined"
              size="small"
              helperText={lastNameError && "Limit exceeded 15 characters"}
              error={lastNameError}
              isRequired={true}
              value={leadLastName}
              onChange={(e) => leadLastNameHandle(e)}
            />
          </div>
          <div className={styles.formName}>
            <MuiPhoneNumber
              variant="outlined"
              style={{ width: "14rem" }}
              MenuProps={{ PaperProps: { sx: { maxHeight: 150 } } }}
              size="small"
              label="Contact No.*"
              isRequired={true}
              value={phoneNumber}
              onChange={(e) => handlePhoneNumberChange(e)}
              id="outlined-basic"
              defaultCountry={"in"}
              disableAreaCodes={true}
              countryCodeEditable={false}
            />
            <TextField
              style={{ width: "14rem" }}
              id="outlined-basic"
              label="Email Address*"
              variant="outlined"
              isRequired={true}
              value={leadEmail}
              size="small"
              onKeyDown={(e) =>
                exceptThisSymbols2.includes(e.key) &&
                e.preventDefault()
              }
              error={emailError}
              helperText={emailError ? leadEmail.length == 0 ? "Please input email" : "Invalid email address" : ""}
              onChange={(e) => handleEmailChange(e)}
            />
          </div>
          <div className={styles.formName}>
            <TextField
              style={{ width: "30rem" }}
              id="outlined-basic"
              label="Address*"
              variant="outlined"
              size="small"
              isRequired={true}
              value={leadAddress}
              onChange={(e) => setLeadAddress(e.target.value)}
            />
          </div>
          <div className={styles.formName}>
            <TextField
              style={{ width: "14rem" }}
              id="outlined-basic"
              label="City*"
              size="small"
              variant="outlined"
              isRequired={true}
              value={leadCity}
              onChange={(e) => leadCityHandle(e)}
            />

            <TextField
              style={{ width: "14rem" }}
              id="outlined-basic"
              label="Postal Code*"
              variant="outlined"
              size="small"
              isRequired={true}
              value={leadCityCode}
              error={errorPin}
              helperText={errorPin ? leadCityCode.length == 0 ? "Please enter city code" : "Invalid Pin Code" : ""}
              onChange={(e) => handlePinCodeChange(e)}
              InputProps={{
                inputMode: "numeric",
              }}
            />
          </div>
          <div className={styles.formName}>
            <FormControl style={{ width: "14rem" }} size="small">
              <InputLabel id="demo-select-large-label">Work Scope</InputLabel>
              <Select
                labelId="demo-select-large-label"
                id="demo-select-large"
                value={workScope}
                label="Work Type"
                onChange={(e) => setWorkScope(e.target.value)}
                MenuProps={{ PaperProps: { sx: { maxHeight: 150 } } }}
              >
                {leadWorkTypes &&
                  leadWorkTypes?.map((leadWorkType) => {
                    return (
                      <MenuItem value={leadWorkType.type}>
                        {leadWorkType.type}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>

            <TextField
              style={{ width: "14rem" }}
              id="outlined-basic"
              label="Budget"
              size="small"
              variant="outlined"
              isRequired={true}
              value={budget}
              onChange={(e) => handleBudget(e)}
              InputProps={{
                inputMode: 'numeric',
                startAdornment: <InputAdornment position="start">₹</InputAdornment>
              }}
            />
          </div>
          <div className={styles.formName}>
            <TextField
              style={{ width: "14rem" }}
              id="outlined-basic"
              label="Area"
              size="small"
              variant="outlined"
              // isRequired={true}
              InputProps={{
                startAdornment: <InputAdornment position="start">
                  <Select
                    variant="standard"
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={areaUnit}
                    renderValue={() => areaUnit}
                    label="Unit"
                    // onChange={handleChange}
                    InputProps={{
                      disableUnderline: true, // <== added this
                    }}
                  >
                    <MenuItem sx={{ minWidth: 200 }} onClick={() => setAreaUnit('sqft')} value={'sqft'} style={{ display: 'flex', justifyContent: 'space-between' }} ><div className={styles.areaUnit}>Sqft</div><div className={styles.areaDescription}>Square feet</div></MenuItem>
                    <MenuItem sx={{ minWidth: 200 }} onClick={() => setAreaUnit('sqm')} value={'sqm'} style={{ display: 'flex', justifyContent: 'space-between' }} ><div className={styles.areaUnit}>Sqm</div><div className={styles.areaDescription}>Square meter</div></MenuItem>
                  </Select>
                </InputAdornment>
              }}
              value={leadArea}
              onChange={handleLeadArea}
            />

            <FormControl style={{ width: "14rem" }} size="small">
              <InputLabel id="demo-select-large-label">Lead Source</InputLabel>
              <Select
                labelId="demo-select-large-label"
                id="demo-select-large"
                value={leadSource}
                label="Lead Source"
                onChange={(e) => handleLeadSource(e)}
                MenuProps={{ PaperProps: { sx: { maxHeight: 150 } } }}
              >
                {leadSources &&
                  leadSources?.map((leadSource) => {
                    return (
                      <MenuItem value={leadSource.id}>
                        {leadSource.leadSource}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </div>
          <div className={styles.formName}>
            <FormControl style={{ width: "14rem" }} size="small">
              <InputLabel id="demo-select-large-label">Source name</InputLabel>
              <Select
                labelId="demo-select-large-label"
                id="demo-select-large"
                value={sourceName}
                label="Source name"
                error={leadSourceClicked && !selectedLeadSource}
                helperText="Please select a Lead source"
                onClick={() => setLeadSourceClicked(true)}
                onBlur={() => setLeadSourceClicked(false)}
                onChange={(e) => handleSourceName(e)}
                MenuProps={{
                  PaperProps: {
                    sx: { maxHeight: 150 },
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                  },
                }}
              >
                {sourceNames &&
                  sourceNames?.map((sourceName) => {
                    if (sourceName.id == leadSource) {
                      return (
                        <MenuItem value={sourceName?._id}>
                          {sourceName.sourceName}
                        </MenuItem>
                      );
                    }
                  })}
              </Select>
              {!selectedLeadSource && leadSourceClicked && <FormHelperText><span style={{ color: '#d32f2f' }}>Please select lead source</span></FormHelperText>}
            </FormControl>

            {userData?.rolesAssigned?.crm?.canAssign && (
              <FormControl style={{ width: "14rem" }} size="small">
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={teamLeadArr?.map((teamLead) => { return { label: teamLead.fullName, id: teamLead._id } }
                  )}
                  onChange={(object, value) => setAssignedTo(value?.id)}
                  // sx={{ width: 225 }}
                  renderInput={(params) => (
                    <TextField {...params} size="small" label="Assign To" />
                  )}
                // MenuProps={{ PaperProps: { sx: { maxHeight: 100 } } }}
                />
              </FormControl>
            )}
          </div>
          <Modal.Footer>
            <div className={styles.closeBtn}>
              <button
                className={styles.createModalClose}
                onClick={handleNewLeadClose}
              >
                <span
                  style={{
                    color: "#C9C9C9",
                  }}
                >
                  Cancel{" "}
                </span>
              </button>
              <button className={styles.createModalSave} onClick={handleSubmit}>
                <span
                  style={{
                    color: "#FFF",
                  }}
                >
                  Add Lead{" "}
                </span>
              </button>
            </div>
          </Modal.Footer>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default AddLeadModalNew;
