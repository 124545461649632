import React, { useContext, useEffect } from "react";
import LmsTableTh from "../LmsTableTh";
import { useParams } from "react-router-dom";
import LmsTableItem from "../LmsTableItem";
import LeadInfoSidePanel from "./LeadInfoSidePanel";
import styles from "./LeadManagement.module.css";
import desquoContext from "../../../picontext/DashBoard/desquoContext";
import UnAssignedSidebardebar from "./UnAssignedSidebar"
import { useSelector } from "react-redux";

function LeadManagement({ setRerender, rerender, setUpdatePanel, updatePanel }) {
  let { tab } = useParams();
  const context = useContext(desquoContext);
  const { leads, setLeads, activeLead } = context;
  const isFilterOpen = useSelector((state) => state.lmsSlice.isFilterOpen);
  const showSourceLeads = (email) => {
    const sortedLeads = leads?.data?.filter((lead) => { if (lead.architectDetails?.email === email) return true })
    const x = leads;
    x['data'] = sortedLeads;
    setLeads(x);
    setRerender(!rerender)
  }

  useEffect(() => {
    localStorage.removeItem('selectedLeadId')
  })


  return (
    <>
      <div
        style={{
        
          marginTop: isFilterOpen ? '10rem' : null

        }}
        className={`or-scb br-5 fixed-tb resize-vertical  scb-none  ${styles.lmsContainer}`}
      >
        <div className=" or-scb br-5 fixed-tb resize-vertical  scb-none border-bottom ablock scrollit"
          style={{
            height:
              window.screen.width > 1341 ? "72vh" : "67vh",
          }}>
          <table
            id="td_table"
            className="fw-noraml table space table-hover table-borderless pfs-12 w-100 td-overflow-wrap px-2"
            style={{ height: 'fit-content' }}
          >
            <thead
              id="th"
              className="bcl-lor bg-white"
              style={
                {
                  display: 'flex',
                  position: 'fixed',
                  justifyContent: 'space-between',
                  top: '10rem',
                  width: '60%',
                  marginTop: isFilterOpen ? '10rem' : null
                }
              }
            >
              <LmsTableTh />
            </thead>
            <tbody
              style={{
                marginTop: isFilterOpen ? '10rem' : "20px",
                height: isFilterOpen ? '48%' : '68%',
                paddingBottom:window.screen.width<1400?'2%':null,
              }}
              className={styles.tbodyContainer}
            >
              {leads?.data?.map((e, i) => {
                return <LmsTableItem key={i + e?._id} idx={i} leadData={e} showSourceLeads={showSourceLeads} />;
              })}
            </tbody>
          </table>
        </div>
        <div  className={styles.sidePanel}>
          {tab != 8 && activeLead?.assignedTo ? <LeadInfoSidePanel setUpdatePanel={setUpdatePanel} updatePanel={updatePanel} tab={tab} /> : <UnAssignedSidebardebar tab={tab} />}
        </div>
      </div>
    </>
  );
}

export default LeadManagement;
